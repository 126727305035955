import React from "react";

import { AppHeader } from "components/AppHeader";
import { BackToCoreButton } from "components/BackToCoreButton";

import styles from "./WelcomePageHeader.module.scss";

export const WelcomePageHeader = () => {
  return (
    <AppHeader rightSide={<BackToCoreButton />}>
      <div className={styles.welcomePageHeader}>
        <h2 data-cy='welcome-page-title'>Welcome to BF Identity</h2>
        <p>Note that each Tier determines your eligibility to use different services on the platform</p>
      </div>
    </AppHeader>
  );
};
