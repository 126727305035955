import React, { useState } from "react";

import { useFormik } from "formik";
import { any, filter, pathOr, pipe } from "ramda";

import { DocumentCopySVG, DocumentTextSVG } from "assets/icons";
import { poaDocument, poaDocumentPreview } from "assets/images";
import { Alert } from "components/Alert";
import { BackButton } from "components/BackButton";
import DocumentThumbnail from "components/DocumentThumbnail/DocumentThumbnail";
import { FileUpload } from "components/FileUpload";
import { RolledList } from "components/RolledList";
import { SubmitButton } from "components/SubmitButton";
import { acceptableDocuments } from "constants/kyc";
import { useFormikActions, useKycData, useMutateKyc, useStepsProgress } from "hooks";
import { DocumentFile, FileItem } from "types/formData";

import { validationSchema } from "./validationSchema";

import styles from "../UploadDocuments.module.scss";

type Props = {
  handleGoNext: () => void;
};

export const UploadProofOfAddress = ({ handleGoNext }: Props) => {
  const { data } = useKycData();
  const updateKyc = useMutateKyc();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const { goToPreviousScreen } = useStepsProgress();
  const initialValues = {
    poaDocuments: pipe(
      pathOr<DocumentFile[]>([], ["last", "documents"]),
      filter<DocumentFile>((doc) => doc.type === "proof_of_address")
    )(data) as (DocumentFile | FileItem)[],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      const res = await handleDocuments(["poaDocuments"]);
      if (!res) return;
      handleGoNext();
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { handleDocuments, getError, handleFileAdd, handleFileRemove } = useFormikActions(formik);

  const anyFileHasError = any((item: any) => item.error, formik.values.poaDocuments);

  const handleGoPrev = async () => {
    updateKyc.mutateAsync({ currentStep: 5 }).then(() => {
      goToPreviousScreen();
    });
  };

  return (
    <div className={styles.uploadDocuments}>
      <h2 data-cy='upload-poa-title'>Upload Proof of Address</h2>
      <p>Proof of Address document must be different from your photo ID and must be in Roman Lettering</p>
      <Alert
        description='All documents need to be dated and less than 90 days old at the time of submission'
        type='warning'
        greyOut={true}
      />
      <RolledList
        header={
          <>
            <DocumentCopySVG className={styles.listIcon} />
            <span className={styles.listHeader}>Acceptable documents</span>
          </>
        }
        visibleAmount={4}
        options={acceptableDocuments}
        className={styles.list}
      />
      <RolledList
        header={
          <>
            <DocumentTextSVG className={styles.listIcon} />
            <span className={styles.listHeader}>The document should demonstrate</span>
          </>
        }
        visibleAmount={4}
        options={["Residential address", "Issuer’s letter head", "Date"]}
        className={styles.list}
      />
      <div className={styles.sampleWrapper} onClick={() => setLightboxOpen(true)}>
        <p>Sample</p>
        <DocumentThumbnail samples={[poaDocument]} samplesPreview={[poaDocumentPreview]} />
      </div>
      <FileUpload
        handleAdd={handleFileAdd("poaDocuments")}
        handleRemove={handleFileRemove("poaDocuments")}
        documentType='proof_of_address'
        documentProcess='verification'
        files={formik.values.poaDocuments}
        error={getError("poaDocuments")}
        multiple
        disabled={formik.isSubmitting}
      />
      <SubmitButton
        onClick={formik.submitForm}
        disabled={!formik.isValid || anyFileHasError}
        loading={formik.isSubmitting}
      >
        Continue
      </SubmitButton>
      <BackButton onClick={handleGoPrev} />
    </div>
  );
};
