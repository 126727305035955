export type ITimeLeft = {
  hours: number;
  minutes: number;
  seconds: number;
};

export const formatTime = (time: number) => {
  return time.toString().padStart(2, "0");
};

export const formatTimeLeft = (timeLeft?: ITimeLeft) => {
  if (!timeLeft) return "";

  return `${formatTime(timeLeft?.hours ?? 0)}:${formatTime(timeLeft?.minutes ?? 0)}:${formatTime(
    timeLeft?.seconds ?? 0
  )}`;
};

export const timeIsUp = (timeLeft?: ITimeLeft) => {
  if (!timeLeft) return false;

  return timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0;
};
