import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isNil, prop } from "ramda";

import { submitEdd } from "api/edd";
import { submitFullCertification } from "api/fullCertification";
import { submitKyc } from "api/kyc";
import { submitSelfCertification } from "api/selfCertification";
import { submitUkRegulations } from "api/ukRegulations";
import { submitVirtualAssets } from "api/virtualAssets";
import { useUser } from "hooks";
import { ProcessType } from "types/process";

import { useQueryError } from "./useQueryError";

export const useSubmit = (process: ProcessType, skipRefershUserData?: boolean) => {
  const { data: userData } = useUser();
  const { handleError } = useQueryError();
  const queryClient = useQueryClient();

  const mutationFn = async () => {
    const userType = prop("type", userData);
    if (isNil(userType)) return;
    switch (process) {
      case "kyc": {
        return submitKyc(userType);
      }
      case "virtualAssets": {
        return submitVirtualAssets(userType);
      }
      case "selfCertification": {
        return submitSelfCertification(userType);
      }
      case "certification": {
        return submitFullCertification(userType);
      }
      case "edd": {
        return submitEdd();
      }
      case "ukRegulations": {
        return submitUkRegulations(userType);
      }
    }
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      if (!skipRefershUserData) {
        queryClient.invalidateQueries({ queryKey: ["userData"] });
      }
    },
    onError: (error: any) => handleError(error),
  });
};
