import React from "react";

import { Tooltip as ANTDTooltip, TooltipProps } from "antd";
import cn from "classnames";

import styles from "./Tooltip.module.scss";

type Props = {
  shouldDisplay?: boolean;
} & TooltipProps;

export const Tooltip = ({ title, className, children, shouldDisplay = true, ...rest }: Props) => {
  if (!shouldDisplay) {
    return <>{children}</>;
  }

  return (
    <ANTDTooltip title={title} overlayClassName={cn(styles.tooltip, className)} color={"#24052BF5"} {...rest}>
      {children}
    </ANTDTooltip>
  );
};

export default Tooltip;
