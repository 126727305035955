import React from "react";

import { Alert as AntDAlert, AlertProps } from "antd";
import cn from "classnames";

import { RedDangerSVG, DangerSVG, TickCircleSVG } from "assets/icons";

import styles from "./Alert.module.scss";

type Props = AlertProps & {
  greyOut?: boolean;
};

export const Alert = ({ className, type = "success", showIcon = true, icon, greyOut = false, ...props }: Props) => {
  if (!icon) {
    switch (type) {
      case "error":
        icon = <RedDangerSVG />;
        break;
      case "warning":
        icon = <DangerSVG />;
        break;
      case "success":
        icon = <TickCircleSVG className={styles.successIcon} />;
        break;
      default:
        showIcon = false;
        break;
    }
  }

  return (
    <AntDAlert
      className={cn(styles.alert, styles[type], className, greyOut ? styles.greyOut : null)}
      type={type}
      showIcon={showIcon}
      icon={icon}
      {...props}
    />
  );
};
