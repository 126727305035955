import { PROFILE_TIER } from "constants/account";
import { ObjectValues } from "types/utils";
import { ApiService } from "utils/requestService";

export type TierAgreementData = {
  tier: ObjectValues<typeof PROFILE_TIER>;
  key: string;
};

export const createTierAgreement = (data: TierAgreementData) => ApiService.post("/tier_agreements", data);
