import { atom } from "recoil";

export type StepsProgress = {
  currentStep: number;
  currentScreen: number;
  percent: number;
};

export const defaultStepsProgress: StepsProgress = {
  currentStep: 1,
  currentScreen: 1,
  percent: 5,
} as const;

export const stepsProgressAtom = atom<StepsProgress>({
  key: "stepsProgressAtom",
  default: defaultStepsProgress,
});
