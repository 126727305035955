export const PROOF_OF_ADDRESS_TYPE = {
  selfie: "selfie",
  screenshot: "screenshot",
} as const;

export const EMPLOYMENT_STATUS = {
  employed: "employed",
  selfEmployed: "self_employed",
  student: "student",
  businessOwner: "business_owner",
  unemployed: "unemployed_retired_homemaker",
} as const;

export const ORGANIZATION_SIZE = {
  to10: "to10",
  to50: "to50",
  over50: "over50",
} as const;

export const REPRESENTATIVE_TYPE = {
  director: "director",
  trustee: "trustee",
  shareholder: "shareholder",
  director_and_shareholder: "director_and_shareholder",
} as const;

export const UK_REGULATIONS_TYPE = {
  restrictedInvestor: "restrictedInvestor",
  hnwInvestor: "hnwInvestor",
} as const;

export const acceptableDocuments = [
  "Bank Statements",
  "Regulated Financial Institution statements",
  "Credit card statements",
  "Utility bills - such as Gas bill, Electricity bill, Water bill, Internet bill",
  "Certificate of Voters Registration",
  "Tax Assessments",
  "Mortgage Statements",
  "Government Issued Official Documents - e.g. Residential Tax Rates, or other government based residential tax rates",
  "Correspondents between you and the government authority regarding the receipt of benefits, such as Pension",
  "Any other Government Issued Official Documents",
  "TV Licence",
  "Landline Telephone bill (excluding mobile phone)",
  "Road Tax",
  "Household Content Insurance",
  "Home Building Insurance",
  "Personal Car Insurance",
  "Drivers Licence Front and Back (if Not used as Photo ID), *ID must contain current Residential Address",
  "Brokerage or other financial Statement",
];
