import { prop } from "ramda";

import { ProfileType } from "types/account";
import { KycInd, KycOrg } from "types/kyc";
import { ProcessData } from "types/process";
import { AdapterMapper } from "types/utils";

class KycAdapterClass {
  commonMapper = {
    status: prop("status"),
    currentStep: prop("currentStep"),
    startable: prop("startable"),
    rejectionReasons: prop("rejectionReasons"),
    addressCountry: prop("addressCountry"),
    addressLine1: prop("addressLine1"),
    addressLine2: prop("addressLine2"),
    city: prop("city"),
    state: prop("state"),
    postcode: prop("postcode"),
    phone: prop("phone"),
    phoneCountry: prop("phoneCountry"),
    phonePrefix: prop("phonePrefix"),
    taxId: prop("taxId"),
    documents: prop("documents"),
    confirmAuthority: prop("confirmAuthority"),
    confirmDataValidity: prop("confirmDataValidity"),
    sanctionedCountryQuestions: prop("sanctionedCountryQuestions"),
  } as const;

  individualMapper = {
    ...this.commonMapper,
    documentCountry: prop("documentCountry"),
    nationality: prop("nationality"),
    name: prop("name"),
    birthday: prop("birthday"),
    veriffStatus: prop("veriffStatus"),
    onlyDataChange: prop("onlyDataChange"),
    secondaryNationalities: prop("secondaryNationalities"),
    placeOfBirth: prop("placeOfBirth"),
    proofOfAddressType: prop("proofOfAddressType"),
    employmentStatus: prop("employmentStatus"),
    businessName: prop("businessName"),
    businessSector: prop("businessSector"),
    occupation: prop("occupation"),
    hasDocumentInEnglish: prop("hasDocumentInEnglish"),
    hasDocumentTranslation: prop("hasDocumentTranslation"),
  } as const;

  organizationalMapper = {
    ...this.commonMapper,
    organizationName: prop("organizationName"),
    registrationNumber: prop("registrationNumber"),
    incorporationCountry: prop("incorporationCountry"),
    principle: prop("principle"),
    principleDetails: prop("principleDetails"),
    businessActivities: prop("businessActivities"),
    hasOffice: prop("hasOffice"),
    hasSameOfficeAddress: prop("hasSameOfficeAddress"),
    officeAddressCountry: prop("officeAddressCountry"),
    officeState: prop("officeState"),
    officeCity: prop("officeCity"),
    officePostcode: prop("officePostcode"),
    officeAddressLine1: prop("officeAddressLine1"),
    officeAddressLine2: prop("officeAddressLine2"),
    hasPrimaryOtherAddress: prop("hasPrimaryOtherAddress"),
    primaryAddressCountry: prop("primaryAddressCountry"),
    primaryState: prop("primaryState"),
    primaryCity: prop("primaryCity"),
    primaryPostcode: prop("primaryPostcode"),
    primaryAddressLine1: prop("primaryAddressLine1"),
    primaryAddressLine2: prop("primaryAddressLine2"),
    financialInstitution: prop("financialInstitution"),
    clientsMoneyInvestment: prop("clientsMoneyInvestment"),
    exemptionFromRegulation: prop("exemptionFromRegulation"),
    regulatedToClientsMoney: prop("regulatedToClientsMoney"),
    regulatorInformation: prop("regulatorInformation"),
    representatives: prop("representatives"),
    sizeOfOrganization: prop("sizeOfOrganization"),
    criminalConnections: prop("criminalConnections"),
    criminalConnectionsDetails: prop("criminalConnectionsDetails"),
    declaredBankruptcy: prop("declaredBankruptcy"),
    declaredBankruptcyDetails: prop("declaredBankruptcyDetails"),
    hasBeenRejectedByOther: prop("hasBeenRejectedByOther"),
    hasBeenRejectedByOtherDetails: prop("hasBeenRejectedByOtherDetails"),
    highRiskRelationships: prop("highRiskRelationships"),
    highRiskRelationshipsDetails: prop("highRiskRelationshipsDetails"),
    invitesSent: prop("invitesSent"),
    secondaryNationalities: prop("secondaryNationalities"),
    placeOfBirth: prop("placeOfBirth"),
    proofOfAddressType: prop("proofOfAddressType"),
    employmentStatus: prop("employmentStatus"),
    businessName: prop("businessName"),
    businessSector: prop("businessSector"),
    occupation: prop("occupation"),
  } as const;

  takeOutData = (type: ProfileType, item: unknown) => {
    const mapper = type === "individual" ? this.individualMapper : this.organizationalMapper;

    return Object.keys(mapper).reduce((acc, key) => {
      const fn = mapper[key as keyof typeof mapper];
      acc[key] = (fn as (item: unknown) => unknown)(item);
      return acc;
    }, {} as any);
  };

  toModel<T extends ProcessData<unknown>>(item: T, type: ProfileType) {
    return {
      last: this.takeOutData(type, item.last),
      current: this.takeOutData(type, item.current),
    };
  }
}

export const KycAdapter = new KycAdapterClass();
