import React, { ChangeEvent, DragEvent, InputHTMLAttributes, ReactNode, useRef, useState } from "react";

import { Col, Row } from "antd";
import cn from "classnames";
import { any, filter, isEmpty, map, pipe, replace, split } from "ramda";

import { DocumentJpgSVG, DocumentPdfSVG, DocumentTextSVG, TrashSVG } from "assets/icons";
import { InputWrapper, InputWrapperProps } from "components/InputWrapper";
import { DocumentFile, DocumentType, DocumentOfProcess, FileItem } from "types/formData";

import styles from "./FileUpload.module.scss";

export type FileUploadProps = {
  wrapperClassName?: string;
  handleAdd: (files: FileItem[]) => void;
  handleRemove: (file: DocumentFile | FileItem) => void;
  files: (DocumentFile | FileItem)[];
  documentType: DocumentType;
  documentProcess: DocumentOfProcess;
  icon?: ReactNode;
  description?: ReactNode;
} & InputWrapperProps &
  InputHTMLAttributes<HTMLInputElement>;

export const FileUpload = ({
  label,
  optional,
  error,
  wrapperClassName,
  className,
  handleAdd,
  handleRemove,
  files,
  documentType,
  documentProcess,
  icon = <DocumentTextSVG />,
  description = (
    <>
      <span>
        Drop file here, or <b>browse</b>
      </span>
      <span>(JPG, PNG, PDF, max 2MB)</span>
    </>
  ),
  accept = "application/pdf,image/*",
  disabled,
  ...rest
}: FileUploadProps) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const acceptedMimeTypes = !!accept ? pipe(split(","), map(replace("*", "")))(accept) : [];
  const checkMimeType = (file: File) =>
    isEmpty(acceptedMimeTypes) ? true : any((type) => file.type.startsWith(type), acceptedMimeTypes);

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (["dragenter", "dragover"].includes(e.type)) {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const createFileItems = map<File, FileItem>((file) => ({
    file,
    id: `${file.name}_${Date.now()}`,
    type: documentType,
    name: file.name,
    process: documentProcess,
  }));

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = filter((item) => checkMimeType(item), Array.from(e.dataTransfer.files || []));
    if (!isEmpty(files) && !disabled) {
      handleAdd(createFileItems(files));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (!isEmpty(files)) {
      handleAdd(createFileItems(files));
    }
  };

  const handleDropzoneClick = () => {
    inputRef.current?.click();
  };

  const renderFile = (item: DocumentFile | FileItem) => {
    const icon = item.name.endsWith(".pdf") ? (
      <DocumentPdfSVG className={styles.fileIcon} />
    ) : (
      <DocumentJpgSVG className={styles.fileIcon} />
    );

    const fileError = (item as FileItem).error;

    return (
      <div key={item.id}>
        <Row gutter={10} align='middle' className={cn(styles.fileItem, { [styles.error]: fileError })}>
          <Col flex='none' className={styles.iconHolder}>
            {icon}
          </Col>
          <Col flex='auto'>{item.name}</Col>
          <Col
            flex='none'
            className={cn(styles.iconHolder, styles.trashHolder)}
            onClick={() => {
              handleRemove(item);
            }}
          >
            <TrashSVG className={styles.trashIcon} />{" "}
          </Col>
        </Row>
        {fileError ? <p className={styles.fileError}>{fileError}</p> : null}
      </div>
    );
  };

  return (
    <InputWrapper
      label={label}
      optional={optional}
      error={error}
      className={cn(styles.fileUploadWrapper, wrapperClassName)}
    >
      <div className={styles.fileUpload}>
        <input type='file' ref={inputRef} onChange={handleInputChange} accept={accept} disabled={disabled} {...rest} />
        <div
          data-cy={`upload-field-${documentType}`}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          onClick={handleDropzoneClick}
          className={cn(styles.dropzone, className, { [styles.active]: dragActive, [styles.error]: !!error })}
        >
          {icon}
          {description}
        </div>
        {files.map((file) => renderFile(file))}
      </div>
    </InputWrapper>
  );
};
