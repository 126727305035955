import * as Yup from "yup";

import { BOOLEAN_ANSWER } from "constants/formData";
import { checkString, checkBooleanAnswer, checkUserText } from "utils/validation";

export const validationSchema = Yup.object().shape({
  addressCountry: checkString,
  addressLine1: checkUserText,
  addressLine2: Yup.string().trim().nullable().isNotLogogram(),
  city: checkUserText,
  state: checkUserText,
  postcode: checkString.isPostCode("addressCountry"),
  placeOfBirth: checkString,
  nationality: checkString,
  hasOtherNationality1: checkBooleanAnswer,
  otherNationality1: Yup.string()
    .nullable()
    .when("hasOtherNationality1", {
      is: BOOLEAN_ANSWER.yes,
      then: () => checkString,
    }),
  hasOtherNationality2: Yup.string()
    .nullable()
    .when("hasOtherNationality1", {
      is: BOOLEAN_ANSWER.yes,
      then: () => checkBooleanAnswer,
    }),
  otherNationality2: Yup.string()
    .nullable()
    .when("hasOtherNationality2", {
      is: BOOLEAN_ANSWER.yes,
      then: () => checkString,
    }),
});
