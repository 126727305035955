import { UseQueryOptions, QueryKey } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { redirectToCore } from "api/authentication";
import { BFID_TOKEN } from "constants/authentication";
import { IAccount } from "types/account";
import { axiosInstance, ApiService } from "utils/requestService";

import { appsignal } from "../appsignal";

import { useLocalStorage } from "./useLocalStorage";
import { useQueryData } from "./useQueryData";
import { useUser } from "./useUser";

export const useUserData = (
  options?: Partial<UseQueryOptions<IAccount, Error & AxiosError<unknown, any>, IAccount, QueryKey>>
) => {
  const { getItem, setItem } = useLocalStorage();
  const { setUserData } = useUser();

  const searchParams = new URLSearchParams(location.search);
  const searchToken = searchParams.get("token");

  const userToken = getItem(BFID_TOKEN);

  const queryKey: QueryKey = ["userData"];
  const queryFn = async () => {
    try {
      if (!searchToken && !userToken) {
        redirectToCore();
        throw new Error("User not found");
      }

      if (userToken) {
        ApiService.setAxiosAuthToken(userToken);
      }

      const useMeEndpoint = "/me";
      const url = searchToken ? `${useMeEndpoint}?token=${searchToken}` : useMeEndpoint;

      const response = await axiosInstance.get<IAccount>(url);

      setItem(BFID_TOKEN, response.headers.authorization);
      ApiService.setAxiosAuthToken(response.headers.authorization);
      setUserData(response.data);

      if (searchToken) {
        searchParams.delete("token");
        const params = searchParams.toString();
        window.history.replaceState(
          undefined,
          "",
          `${location.origin}${location.pathname}${params ? `?${params}` : ""}`
        );
      }

      return response.data; // Return the response data directly
    } catch (error: any) {
      if (error?.response?.status === 401) {
        setTimeout(() => {
          redirectToCore();
        }, 3000);
        throw new Error("Unauthorized");
      }
      appsignal.sendError(error);
      throw error;
    }
  };

  return useQueryData(queryKey, queryFn, {
    ...options,
    queryKey,
  });
};
