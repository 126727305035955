import { appsignal } from "../appsignal";

export const useLocalStorage = () => {
  const setItem = <T = string>(key: string, value: T) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const getItem = <T = string>(key: string) => {
    const storedData = window.localStorage.getItem(key);
    try {
      return storedData && (JSON.parse(storedData) as T);
    } catch (err: any) {
      console.log(`useLocalStorage getItem Error trying to get "${key}": `, err);
      appsignal.sendError(err);
      return null;
    }
  };

  const removeItem = (key: string) => {
    window.localStorage.removeItem(key);
  };

  return { setItem, getItem, removeItem };
};
