import React, { ReactNode } from "react";

import cn from "classnames";

import { QuestionSVG } from "assets/icons";
import { Tooltip } from "components/Tooltip";

import styles from "./InputWrapper.module.scss";

export type InputWrapperProps = {
  label?: ReactNode;
  optional?: boolean;
  error?: string;
  tooltip?: ReactNode;
  className?: string;
};

type Props = { children: ReactNode } & InputWrapperProps;

export const InputWrapper = ({ children, label, optional, tooltip, error, className }: Props) => {
  return (
    <div className={cn(styles.inputWrapper, className)}>
      {label ? (
        <div className={styles.label}>
          {label}
          {optional ? <span className={styles.labelOptional}>(optional)</span> : null}
          {tooltip ? (
            <Tooltip title={tooltip}>
              <span className={styles.tooltipIcon}>
                <QuestionSVG />
              </span>
            </Tooltip>
          ) : null}
        </div>
      ) : null}
      <div className={cn(styles.inputContainer, { [styles.invalid]: error })}>{children}</div>
      {error ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
};
