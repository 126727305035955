import { useEffect, useState } from "react";

const LOCAL_STORAGE_IS_TABLE_CLOSED = "is-comparison-table-closed";

export const useStoreInfoAboutExpandInLocalStorage = (defaultShowComparison?: boolean) => {
  const [showComparison, setShowComparison] = useState(false);

  const onToggle = () => {
    if (defaultShowComparison) {
      localStorage.setItem(LOCAL_STORAGE_IS_TABLE_CLOSED, String(!showComparison));
    }

    setShowComparison((prev) => !prev);
  };

  useEffect(() => {
    if (defaultShowComparison) {
      if (localStorage.getItem(LOCAL_STORAGE_IS_TABLE_CLOSED) === "false") {
        setShowComparison(false);
      } else {
        setShowComparison(true);
      }
    }
  }, [defaultShowComparison]);

  return {
    showComparison,
    onToggle,
  };
};
