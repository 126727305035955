import React, { MouseEvent, ReactNode } from "react";

import cn from "classnames";

import { Button } from "components/Button";

import styles from "./SubmitButton.module.scss";

type Props = {
  onClick: (event?: MouseEvent) => void;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
};

export const SubmitButton = ({ onClick, className, children = "Submit", disabled, loading }: Props) => {
  return (
    <Button
      data-cy='submit-btn'
      lg
      className={cn(styles.submitBtn, className)}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </Button>
  );
};
