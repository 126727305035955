import React, { useState } from "react";

import { DocumentSampleOverlaySVG } from "assets/icons";
import Lightbox from "components/Lightbox/Lightbox";

import styles from "./DocumentThumbnail.module.scss";

type Props = {
  samples: string[];
  samplesPreview: string[];
};
const DocumentThumbnail = ({ samples, samplesPreview }: Props) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedSamples, setSelectedSamples] = useState<string[]>([]);
  const [activeSampleIndex, setActiveSampleIndex] = useState(0);

  return (
    <>
      <div className={styles.samples}>
        {samples.map((src, index) => (
          <div className={styles.imageContainer} key={src}>
            <img
              src={src}
              key={src}
              alt='sample'
              onClick={() => {
                setLightboxOpen(true);
                setSelectedSamples(samplesPreview);
                setActiveSampleIndex(index);
              }}
            />
            <DocumentSampleOverlaySVG className={styles.overlay} />
          </div>
        ))}
      </div>
      <Lightbox
        open={lightboxOpen}
        index={activeSampleIndex}
        close={() => setLightboxOpen(false)}
        slides={selectedSamples.map((sample) => ({ src: sample }))}
      />
    </>
  );
};

export default DocumentThumbnail;
