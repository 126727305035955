import { removeDocument } from "api/documents";
import { DOCUMENT_TYPE } from "constants/formData";
import { DocumentFile } from "types/formData";

type RemoveDocumentsArgs = {
  documents?: DocumentFile[];
  documentTypes: string[];
  callback: () => void;
};

export const removeDocuments = ({ documents = [], documentTypes, callback }: RemoveDocumentsArgs) => {
  const filesToRemove = documents.filter((d) => documentTypes.includes(d.type)).map((d) => removeDocument(d.id));

  if (filesToRemove) {
    Promise.all(filesToRemove).then(callback);
  }
};
