import React from "react";

import { useLocation } from "react-router-dom";

import { ExportSVG } from "assets/icons";
import { veriffLogo } from "assets/images";
import * as PATHS from "constants/pages";

import styles from "./Footer.module.scss";

export const Footer = () => {
  const location = useLocation();

  const isKycFooter = location.pathname === PATHS.KYC_PATH;

  const getFooterContent = () => {
    if (isKycFooter) {
      return (
        <div>
          <a className={styles.nonClickableText}>KYC Verification is powered by Veriff</a>
          <div>
            <img src={veriffLogo} />
          </div>
        </div>
      );
    }

    return (
      <span>
        Need help?
        <a target='_blank' href={process.env.REACT_APP_CORE_SUPPORT} rel='noreferrer'>
          Visit support <ExportSVG />
        </a>
      </span>
    );
  };

  return <div className={styles.footer}>{getFooterContent()}</div>;
};
