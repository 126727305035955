import React, { KeyboardEvent, ReactNode } from "react";

import { Col, Row } from "antd";
import cn from "classnames";

import styles from "./AnswerOption.module.scss";

export type AnswerOptionProps = {
  option: ReactNode;
  onClick: () => void;
  leftSide?: ReactNode;
  rightSide?: ReactNode;
  className?: string;
  disabled?: boolean;
  dataKey?: string | number;
};

export const AnswerOption = ({
  option,
  onClick,
  leftSide,
  rightSide,
  className,
  disabled,
  dataKey,
}: AnswerOptionProps) => {
  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!disabled && e.code === "Space") {
      onClick();
    }
  };

  return (
    <Row
      data-cy={dataKey}
      className={cn(styles.answerOption, className, { [styles.disabled]: disabled })}
      onClick={!disabled ? onClick : undefined}
      tabIndex={!disabled ? 0 : undefined}
      onKeyPress={handleKeyPress}
    >
      {leftSide ? <Col span={3}>{leftSide}</Col> : null}
      <Col className={styles.answerOptionText} flex='auto'>
        {option}
      </Col>
      {rightSide ? (
        <Col span={3} className={styles.rightSide}>
          {rightSide}
        </Col>
      ) : null}
    </Row>
  );
};
