export const validationMessages = {
  checkboxRequired: "Field must be checked",
  required: "Required",
  maxFilesAllowed: (count: number) => `At most ${count} files are allowed`,
  oneFile: "Only one file allowed",
  wrongEmail: "Invalid email address",
  wrongTIN: "Invalid TIN number",
  wrongPhoneNumber: "Invalid phone number",
  fileRequired: "File is required",
  wrongPostCode: "Invalid post code",
  oneItem: "Pick at least one item",
  maxOneItem: "Pick only one item",
  incorrectLetters: "Incorrect letters",
  readDisclaimer: "Must read & accept the discclaimer agreement",
  emailNotUniq: "Email must be unique",
  representativeRequired: "One representative is required",
  representativeOnlyOne: "Only one account can be representative",
  entityEmail: "Cannot use entity email",
} as const;
