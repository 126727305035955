import { getCountries, getHighRisk, getWithStates } from "api/countries";

import { useQueryData } from "./useQueryData";

export type Country = {
  value: string;
  label: string;
  country_code: string;
};

export const useCountries = () => {
  const { data: countries, isLoading: isLoadingCountries } = useQueryData(["countries"], getCountries);
  const { data: countriesWithStates, isLoading: isLoadingCountriesWithStates } = useQueryData(
    ["countriesWithStates"],
    getWithStates
  );
  const { data: highRiskCountries, isLoading: isLoadingHighRiskCountries } = useQueryData(
    ["highRiskCountries"],
    getHighRisk
  );

  const countriesOptions = countries?.map((country) => ({
    label: country.label,
    value: country.value,
  }));

  return {
    countries,
    countriesOptions,
    countriesWithStates,
    highRiskCountries,
    isLoading: isLoadingCountries && isLoadingCountriesWithStates && isLoadingHighRiskCountries,
  };
};
