import { useEffect, useState } from "react";

import { isMobile } from "utils/platform";

const BREAK_POINTS = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
} as const;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const onResize = () => {
      setWindowSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const isXXS = windowSize.width < BREAK_POINTS.xs;
  const isXS = windowSize.width >= BREAK_POINTS.xs;
  const isSM = windowSize.width >= BREAK_POINTS.sm;
  const isMD = windowSize.width >= BREAK_POINTS.md;
  const isLG = windowSize.width >= BREAK_POINTS.lg;
  const isXL = windowSize.width >= BREAK_POINTS.xl;
  const isXXL = windowSize.width >= BREAK_POINTS.xxl;

  return { windowSize, isXXS, isXS, isSM, isMD, isLG, isXL, isXXL, isMobile: isMobile() || !isLG };
};
