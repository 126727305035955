import { VirtualAssetsAdapter } from "adapters/virtualAssets";
import { ProfileType } from "types/account";
import { ProcessData } from "types/process";
import { VirtualAssetsInd, VirtualAssetsOrg } from "types/virtualAssets";
import { ApiService } from "utils/requestService";

export const getVirtualAssetsData = (type: ProfileType) =>
  ApiService.get<ProcessData<unknown>>(`/${type}/virtual_assets`).then((data) =>
    VirtualAssetsAdapter.toModel(data, type)
  );

export const updateVirtualAssets = (type: ProfileType, data: Partial<VirtualAssetsInd | VirtualAssetsOrg>) =>
  ApiService.patch(`/${type}/virtual_assets`, data);

export const submitVirtualAssets = (type: ProfileType) => ApiService.patch(`/${type}/virtual_assets/submit`);
