import { find } from "ramda";

import sanctionedCountryQuestions, { SanctionedQuestionForCountry } from "../constants/sanctionedCountryQuestions";

export const getCountryByCode = (code: string, countriesOptions: { value: string; label: string }[]) => {
  return find(({ value }) => code === value, countriesOptions)?.label || "";
};

export const getSanctionedQuestionsForCountry = ({
  nationality,
  addressCountry,
  answeredSanctionedQuestionsForCountry = {},
}: {
  nationality: string;
  addressCountry: string;
  answeredSanctionedQuestionsForCountry?: SanctionedQuestionForCountry;
}) => {
  const answeredQuestions = Object.values(answeredSanctionedQuestionsForCountry).reduce(
    (allQuestions, questions) => [...allQuestions, ...questions],
    []
  );

  return Object.keys(sanctionedCountryQuestions).reduce(
    (questions, questionsKey) => ({
      ...questions,
      ...([addressCountry, nationality].includes(questionsKey) && {
        [questionsKey]: sanctionedCountryQuestions[questionsKey].map((question) => ({
          question,
          answer: answeredQuestions.find((answeredQuestion) => answeredQuestion.question === question)?.answer ?? null,
        })),
      }),
    }),
    {} as SanctionedQuestionForCountry
  );
};
