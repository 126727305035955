import React from "react";

import { Select as AntDSelect, SelectProps } from "antd";
import cn from "classnames";

import { ArrowSVG } from "assets/icons";
import { InputWrapper, InputWrapperProps } from "components/InputWrapper";

import styles from "./Select.module.scss";

type Props = { wrapperClassName?: string } & InputWrapperProps & SelectProps;

export const Select = ({
  label,
  optional,
  error,
  tooltip,
  wrapperClassName,
  className,
  placeholder = "Select",
  ...rest
}: Props) => {
  return (
    <InputWrapper label={label} optional={optional} error={error} tooltip={tooltip} className={wrapperClassName}>
      <AntDSelect
        className={cn(styles.select, className)}
        variant='borderless'
        popupClassName={styles.menu}
        placeholder={placeholder}
        suffixIcon={<ArrowSVG />}
        {...rest}
      />
    </InputWrapper>
  );
};
