import React, { useEffect, useState } from "react";

import { Col, Divider, Modal, Row } from "antd";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

import { createTierAgreement } from "api/tierAgreements";
import { Button } from "components/Button";
import { PROFILE_TIER } from "constants/account";
import * as PATHS from "constants/pages";
import { useUser } from "hooks";
import { ProfileTierExclude0, TierAgreement } from "types/account";

import styles from "./TierInfoModal.module.scss";

type Props = {
  tier: ProfileTierExclude0;
  open: boolean;
  openReadme?: boolean;
  handleClose: () => void;
};

const checkTierAgreement = (data: TierAgreement[], agreementKey: string) => {
  return data.some((agreement) => agreement.key === agreementKey);
};

const infoModalMap = {
  [PROFILE_TIER.tier_1]: {
    title: "Tier 1",
    description:
      "By completing Tier 1, you will become a client of BF Virtual Assets Ltd., a BF Virtual Assets Broker based in the British Virgin Islands. This entity will allow you to Deposit, Withdraw, Trade and Stake Virtual Assets like Bitcoin, ETH & USDT etc.",
    termsLink: "https://app.bnktothefuture.com/terms_and_conditions/virtual_assets",
    termsLinkText: "Virtual Assets Terms & Conditions",
    gridTitle: "BF Virtual Assets Ltd.",
    gridRows: [
      {
        gridColumns: [
          {
            title: "COMPANY NO.",
            value: "2096766",
          },
          {
            title: "Financial Services Commission",
            value: "British Virgin Islands ",
          },
        ],
      },
      {
        gridColumns: [
          {
            title: "Virtual Asset Service Provider (VASP)",
            value: "Pending registration",
          },
        ],
      },
      {
        gridColumns: [
          {
            title: "office address",
            value: "Rodus Building, P.O. Box 3093, Road Town, Tortola, VG1110, British Virgin Islands",
          },
        ],
      },
    ],
  },
  [PROFILE_TIER.tier_1_uk_restricted]: {
    title: "Tier 1",
    description:
      "By completing Tier 1, you will become a client of BF Virtual Assets Ltd., a BF Virtual Assets Broker based in the British Virgin Islands. This entity will allow you to Deposit, Withdraw, Trade and Stake Virtual Assets like Bitcoin, ETH & USDT etc.",
    termsLink: "https://app.bnktothefuture.com/terms_and_conditions/virtual_assets",
    termsLinkText: "Virtual Assets Terms & Conditions",
    gridTitle: "BF Virtual Assets Ltd.",
    gridRows: [
      {
        gridColumns: [
          {
            title: "COMPANY NO.",
            value: "2096766",
          },
          {
            title: "Financial Services Commission",
            value: "British Virgin Islands ",
          },
        ],
      },
      {
        gridColumns: [
          {
            title: "Virtual Asset Service Provider (VASP)",
            value: "Pending registration",
          },
        ],
      },
      {
        gridColumns: [
          {
            title: "office address",
            value: "Rodus Building, P.O. Box 3093, Road Town, Tortola, VG1110, British Virgin Islands",
          },
        ],
      },
    ],
  },
  [PROFILE_TIER.tier_2]: {
    title: "Tier 2",
    description:
      "By completing Tier 2, you will become a client of BF Global IB MU, a BF Securities Broker regulated in Mauritius. BF GLOBAL IB MU is regulated by the Mauritius Financial Services Commission (FSC) as a Full Service Dealer, Excluding Underwriting. This entity will allow you to custody, buy & sell and Trade Public Shares and hold a USD balance for share trading under custody.",
    termsLink: "",
    termsLinkText: "",
    gridTitle: "BF GLOBAL IB MU",
    gridRows: [
      {
        gridColumns: [
          {
            title: "COMPANY NO.",
            value: "197710",
          },
          {
            title: "LICENSE NO.",
            value: "GB22201273",
          },
        ],
      },
      {
        gridColumns: [
          {
            title: "Investment Dealer Licence ",
            value: "Full Service Dealer, Excluding underwriting",
          },
          {
            title: "Financial Services Commission",
            value: "Mauritius",
          },
        ],
      },
      {
        gridColumns: [
          {
            title: "office address",
            value: "Suite 302A, 3th Floor, The Catalyst Building, Plot 40, Silicon Ave, Ebene, Republic of Mauritius",
          },
        ],
      },
    ],
  },
  [PROFILE_TIER.tier_3]: {
    title: "",
    description: "",
    termsLink: "",
    termsLinkText: "",
    gridTitle: "",
    gridRows: [],
  },
  [PROFILE_TIER.tier_4]: {
    title: "Tier 4",
    description:
      "By completing Tier 4, you will become a client of BNK To The Future. BNK To The Future is regulated by the Cayman Island Monetary Authority (CIMA). This entity will allow you to invest, custody buy & sell Private Equity and access our asset protection portfolio builder structures for High Net Worth investors.",
    termsLink: "https://app.bnktothefuture.com/terms_and_conditions/securities",
    termsLinkText: "Securities Terms & Conditions",
    gridTitle: "BNK To The Future",
    gridRows: [
      {
        gridColumns: [
          {
            title: "COMPANY NO.",
            value: "CO-296093",
          },
          {
            title: "SECURITIES",
            value: "Registered Person",
          },
          {
            title: "REGISTERED PERSON NO.",
            value: "1601784",
          },
        ],
      },
      {
        gridColumns: [
          {
            title: "office address",
            value: "Willow House, Cricket Square, KY1-1001, Cayman Islands",
          },
        ],
      },
    ],
  },
};

export const tire2PathMap: Record<ProfileTierExclude0, string> = {
  [PROFILE_TIER.tier_1]: PATHS.VIRTUAL_ASSETS_PATH,
  [PROFILE_TIER.tier_1_uk_restricted]: PATHS.VIRTUAL_ASSETS_PATH,
  [PROFILE_TIER.tier_2]: PATHS.SELF_CERTIFICATION_PATH,
  [PROFILE_TIER.tier_3]: PATHS.CERTIFICATION_PATH,
  [PROFILE_TIER.tier_4]: PATHS.CERTIFICATION_PATH,
};

export const TierInfoModal = ({ tier, open, openReadme, handleClose }: Props) => {
  const { data } = useUser();
  const navigate = useNavigate();
  const tierAgreementKey = `${tier}_agreement`;

  const closeModal = () => {
    handleClose();
  };

  const handleClickModalConfirmButton = () => {
    if (openReadme) {
      closeModal();
    } else {
      if (checkTierAgreement(data.tierAgreements[tier], tierAgreementKey)) {
        navigate(tire2PathMap[tier]);
      } else {
        createTierAgreement({
          tier,
          key: tierAgreementKey,
        }).then(() => {
          navigate(tire2PathMap[tier]);
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      width={660}
      centered
      closable
      className={styles.TierInfoModal}
      onCancel={closeModal}
      footer={
        <div className={styles.modalFooter}>
          <Button onClick={handleClickModalConfirmButton}>Ok, I understand</Button>
          {infoModalMap[tier].termsLink && (
            <a target='_blank' rel='noreferrer' href={infoModalMap[tier].termsLink}>
              {infoModalMap[tier].termsLinkText}
            </a>
          )}
        </div>
      }
    >
      <div className={styles.modalContent}>
        <h5 className={styles.modalTitle}>{infoModalMap[tier].title}</h5>
        <p>{infoModalMap[tier].description}</p>

        <div className={styles.modalGrid}>
          <h5 className={styles.modalGridTitle}>{infoModalMap[tier].gridTitle}</h5>
          {infoModalMap[tier].gridRows.map((row, row_idx) => {
            return (
              <Row className={styles.modalGridRow} key={row_idx}>
                {row.gridColumns.map((col) => {
                  return (
                    <Col className={styles.modalGridColumn} key={`${tier}-${col.title}`}>
                      <div className={styles.columnTitle}>{col.title}</div>

                      <div>{col.value}</div>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
