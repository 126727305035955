import React, { ReactNode, MouseEvent } from "react";

import cn from "classnames";

import { Button } from "components/Button";

import styles from "./BackButton.module.scss";

type Props = {
  onClick?: (event: MouseEvent) => void;
  className?: string;
  children?: ReactNode;
  href?: string;
};

export const BackButton = ({ onClick, className, children = "Back", href }: Props) => {
  return (
    <Button data-cy='back-btn' lg type='link' onClick={onClick} className={cn(styles.backBtn, className)} href={href}>
      {children}
    </Button>
  );
};

export const GreenBackButton = ({ onClick, className, children = "Back", href }: Props) => {
  return (
    <Button data-cy='back-btn' lg onClick={onClick} className={cn(styles.greenBackButton, className)}>
      {children}
    </Button>
  );
};
