import { UseQueryOptions, QueryKey } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { isNil } from "ramda";

import { getVirtualAssetsData } from "api/virtualAssets";
import { useUser } from "hooks";

import { useQueryData } from "./useQueryData";

export const useVirtualAssetsData = (
  statusRejected?: boolean,
  options?: Partial<UseQueryOptions<unknown, Error & AxiosError<unknown, any>, unknown, QueryKey>>
) => {
  const { data } = useUser();

  if (isNil(data)) {
    return { data: undefined, isLoading: false, error: null };
  }

  const queryKey: QueryKey = ["virtualAssetsData", data];
  const queryFn = () => getVirtualAssetsData(data.type);

  return useQueryData(queryKey, queryFn, {
    ...options,
    queryKey,
    enabled: statusRejected !== false,
  });
};
