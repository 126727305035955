import { useMutation } from "@tanstack/react-query";
import { isNil } from "ramda";

import { updateUkRegulations, updateUserDecision, userDecision } from "api/ukRegulations";
import { useUser } from "hooks";
import { UkRegulations, UkRegulationsQuizQuestion } from "types/ukRegulations";

import { useQueryError } from "./useQueryError";

type MutationFnNewData = Partial<UkRegulations> & {
  assessmentQuestionnaireAttributes?: { results: UkRegulationsQuizQuestion[] };
  submitCategorization?: boolean;
};

export const useMutateUkRegulations = () => {
  const { data: userData } = useUser();
  const { handleError } = useQueryError();

  return useMutation({
    mutationFn: isNil(userData)
      ? undefined
      : (newData: MutationFnNewData) => updateUkRegulations(userData.type, { ...newData }),
    onError: (error: any) => handleError(error),
  });
};

export const useMutateUserDecision = () => {
  const { data: userData } = useUser();
  const { handleError } = useQueryError();

  return useMutation({
    mutationFn: isNil(userData)
      ? undefined
      : (newData: userDecision) => updateUserDecision(userData.type, { ...newData }),
    onError: (error: any) => handleError(error),
  });
};
