import axios, { AxiosRequestConfig } from "axios";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: { "Content-Type": "application/json" },
});

class ApiServiceClass {
  setAxiosAuthToken = (token: string | null) => {
    axiosInstance.defaults.headers.common.authorization = token || false;
  };

  getFilePreview = <R>(path: string, reqConfig?: AxiosRequestConfig) =>
    axiosInstance
      .get<R>(path, {
        baseURL: process.env.REACT_APP_ID_URL,
        maxRedirects: 0,
        ...reqConfig,
      })
      .then((res) => res.request.responseURL);

  get = <R>(path: string, reqConfig?: AxiosRequestConfig) =>
    axiosInstance.get<R>(path, reqConfig).then((res) => res.data);

  post = <R>(path: string, data?: Record<string, unknown> | FormData, reqConfig?: AxiosRequestConfig) =>
    axiosInstance.post<R>(path, data, reqConfig).then((res) => res.data);

  patch = <R>(path: string, data?: Record<string, unknown>, reqConfig?: AxiosRequestConfig) =>
    axiosInstance.patch<R>(path, data, reqConfig).then((res) => res.data);

  delete = <R>(path: string, reqConfig?: AxiosRequestConfig) =>
    axiosInstance.delete<R>(path, reqConfig).then((res) => res.data);
}

export const ApiService = new ApiServiceClass();
