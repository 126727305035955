import React, { useEffect, useMemo, useState } from "react";

import cn from "classnames";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { UK_REGULATIONS } from "constants/pages";
import { PROCESS_STATUS } from "constants/process";
import { useUser } from "hooks";
import { ITimeLeft } from "utils/timeFormater";

import { Alert } from "./Alert";
import { PendingAlert } from "./UkRestrictedPromotionPendingAlert";
import { SecondTryAlert } from "./UkRestrictedPromotionSecondTryAlert";

import styles from "./Alert.module.scss";

const blogArticleUrl = "https://bnktothefuture.com/fca-promotion-overview/";

const content = {
  firstTry: {
    title: "New UK Regulations",
    description:
      "Due to new UK regulations we are required to collect additional information about how you classify yourself as an investor, followed by a short quiz on your investment knowledge.",
    buttonText: "Start Assessment",
  },
  blocked: {
    title: "New UK Regulations",
    description: (
      <>
        You have failed twice the Assessment for UK Financial Promotion, we are not able to onboard you as a customer at
        the moment. Please take a moment to{" "}
        <a href={blogArticleUrl} target='_blank' rel='noreferrer'>
          learn more
        </a>
        , and contact support if your situation has changed
      </>
    ),
    buttonText: "Contact support",
  },
};

const calculateTimeLeft = (targetDate: string) => {
  const now = new Date();
  const difference = new Date(targetDate).getTime() - now.getTime();

  let timeLeft = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const UkRestrictedPromotionAlert = () => {
  const [pendingTimeLeft, setPendingTimeLeft] = useState<ITimeLeft | undefined>();
  const [retryTimeLeft, setRetryTimeLeft] = useState<ITimeLeft | undefined>();
  const [activeContent, setActiveContent] = useState<
    { title: string; description: string | JSX.Element; buttonText?: string } | undefined
  >();
  const { data } = useUser();
  const navigate = useNavigate();

  const status = useMemo(() => {
    if (data.promotionRequest.currentStatus === PROCESS_STATUS.pending) {
      return "pending";
    }

    if (data.promotionRequest.currentStatus === PROCESS_STATUS.blocked) {
      return "blocked";
    }

    if (data.promotionRequest.lastStatus === PROCESS_STATUS.draft) {
      if (data.promotionRequest.currentStatus === PROCESS_STATUS.rejected) {
        return "secondTry";
      } else {
        return "firstTry";
      }
    }
  }, [data.promotionRequest]);

  useEffect(() => {
    if (status === "firstTry") {
      setActiveContent(content.firstTry);
    }

    if (status === "blocked") {
      setActiveContent(content.blocked);
    }
  }, [status]);

  useEffect(() => {
    if (data.promotionRequest.activatedAt) {
      const interval = setInterval(() => {
        setPendingTimeLeft(calculateTimeLeft(data.promotionRequest.activatedAt ?? ""));
      }, 1000);

      return () => clearInterval(interval);
    }

    if (data.promotionRequest.retriableAt) {
      const interval = setInterval(() => {
        setRetryTimeLeft(calculateTimeLeft(data.promotionRequest.retriableAt ?? ""));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [data]);

  const handleOnButtonClick = () => {
    if (status === "blocked") {
      window.open(process.env.REACT_APP_CORE_SUPPORT, "_blank");
    } else {
      navigate(UK_REGULATIONS);
    }
  };

  if (status === "pending") {
    return <PendingAlert blogArticleUrl={blogArticleUrl} timeLeft={pendingTimeLeft} />;
  }

  if (status === "secondTry") {
    return (
      <SecondTryAlert
        blogArticleUrl={blogArticleUrl}
        startable={data.promotionRequest.startable}
        timeLeft={retryTimeLeft}
      />
    );
  }

  if (!activeContent) {
    return <></>;
  }

  return (
    <Alert
      data-cy='uk-fin-prom-alert'
      className={cn(styles.alert, styles.ukRestrictedPromotion)}
      type='warning'
      message={activeContent.title}
      description={
        <div className={styles.ukRestrictedAlertBody}>
          <p>{activeContent.description}</p>

          <div className={classNames(styles.customAlertAction, styles.ukRegulationsAction)}>
            {activeContent.buttonText && (
              <Button
                data-cy='uk-fin-prom-cta'
                disabled={!data.promotionRequest.startable && status !== PROCESS_STATUS.blocked}
                className={styles.actionBtn}
                onClick={handleOnButtonClick}
              >
                {activeContent.buttonText}
              </Button>
            )}
          </div>
        </div>
      }
      closable={false}
    />
  );
};
