import React, { useEffect } from "react";

import { clone, pickAll } from "ramda";

import CountriesProvider from "contexts/countriesCtx";
import { useKycData, useStepsProgress } from "hooks";
import { KycInd } from "types/kyc";

import { isSanctionedCountryQuestionsRequired } from "./helpers";
import { AddressDetails } from "./ProofOfAddress/AddressDetails";
import { DocumentsLanguage } from "./ProofOfAddress/DocumentsLanguage";
import { UploadDocuments } from "./ProofOfAddress/UploadDocuments";
import { ProofOfIdentityCreated } from "./ProofOfIdentity/ProofOfIdentityCreated";
import { ProofOfIdentityInit } from "./ProofOfIdentity/ProofOfIdentityInit";
import { SanctionedCountryQuestions } from "./SanctionedCountryQuestions";
import { KycSummary } from "./Summary";

type Props = {
  kycData: KycInd;
};

export const IndividualKyc = ({ kycData }: Props) => {
  const {
    stepsProgress: { currentScreen },
    setStepsPercent,
    setCurrentStep,
    goToPreviousScreen,
  } = useStepsProgress();
  const { nationality, addressCountry } = !!kycData
    ? pickAll(["nationality", "addressCountry"], clone(kycData))
    : { nationality: "", addressCountry: "" };

  useEffect(() => {
    switch (currentScreen) {
      case 2: {
        setStepsPercent(40);
        break;
      }
      case 3: {
        setCurrentStep(2);
        setStepsPercent(55);
        break;
      }
      case 4: {
        setCurrentStep(2);
        setStepsPercent(60);
        break;
      }
      case 5: {
        setCurrentStep(2);
        setStepsPercent(75);
        break;
      }
      case 6: {
        setCurrentStep(3);
        setStepsPercent(80);
        break;
      }
      case 7: {
        setCurrentStep(3);
        setStepsPercent(100);
        break;
      }
    }
  }, [currentScreen]);

  const renderBody = () => {
    switch (currentScreen) {
      case 1:
        return <ProofOfIdentityInit />;
      case 2:
        return <ProofOfIdentityCreated />;
      case 3:
        return <AddressDetails />;
      case 4:
        if (!isSanctionedCountryQuestionsRequired([nationality, addressCountry])) {
          goToPreviousScreen();
          return null;
        }
        return <SanctionedCountryQuestions />;
      case 5:
        return <DocumentsLanguage />;
      case 6:
        return <UploadDocuments />;
      case 7:
        return <KycSummary />;
      default:
        return null;
    }
  };

  return <CountriesProvider>{renderBody()}</CountriesProvider>;
};
