import { AlertProps } from "antd";
import { atom } from "recoil";

export type AlertAtomProps = AlertProps & Record<string, unknown>;

type AlertAtom = {
  id: string;
  isVisible: boolean;
  Element: (props: AlertAtomProps) => JSX.Element;
  props?: AlertAtomProps;
};

export const alertsAtom = atom<AlertAtom[]>({
  key: "alertsAtom",
  default: [],
});
