import React, { useState } from "react";

import { Modal } from "antd";
import cn from "classnames";

import { Button, ButtonProps } from "components/Button";

import styles from "./ConfirmButton.module.scss";

export type ConfirmButtonProps = {
  onConfirmHandler: () => void;
  onCancelHandler?: () => void;
  title?: string;
  content?: string;
} & ButtonProps;

export const ConfirmButton = ({
  onConfirmHandler,
  onCancelHandler,
  className,
  children = <>Action</>,
  title = "Confirm",
  content = "Are you sure?",
  ...rest
}: ConfirmButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      <Button lg className={cn(className)} onClick={openModal} {...rest}>
        {children}
      </Button>

      <Modal
        open={open}
        title={<h4 className={styles.modalTitle}>{title}</h4>}
        centered={true}
        width={650}
        closable={true}
        className={styles.customModal}
        onCancel={handleCancel}
        footer={[
          <Button
            className={styles.modalOKButton}
            key='submit'
            onClick={() => {
              onConfirmHandler();
            }}
          >
            Yes
          </Button>,
          <Button
            key='back'
            className={styles.modalCancelButton}
            onClick={() => {
              handleCancel();
              onCancelHandler?.();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <span className={styles.modalContent}>{content}</span>
      </Modal>
    </>
  );
};
