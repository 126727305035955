import { UkRegulationsAdapter } from "adapters/ukRegulationsAdapter";
import { ProfileType } from "types/account";
import { UkRegulations } from "types/ukRegulations";
import { ObjectValues } from "types/utils";
import { ApiService } from "utils/requestService";

export const USER_DECISION = {
  continue: "continue",
  decline: "decline",
} as const;

export type userDecision = {
  decision: ObjectValues<typeof USER_DECISION>;
};

export const getUkRegulations = (type: ProfileType) =>
  ApiService.get<UkRegulations>(`/${type}/promotion_request`).then((data) => UkRegulationsAdapter.toModel(data));

export const updateUkRegulations = (type: ProfileType, data: Partial<UkRegulations>) =>
  ApiService.patch(`/${type}/promotion_request`, data);

export const submitUkRegulations = (type: ProfileType, data?: Partial<UkRegulations>) =>
  ApiService.patch(`/${type}/promotion_request/submit`, data);

export const updateUserDecision = (type: ProfileType, data?: Partial<userDecision>) =>
  ApiService.patch(`/${type}/promotion_request/user_decision`, data);
