import { atom } from "recoil";

import { UkRegulations } from "types/ukRegulations";

export const IndUkRegultions = atom<Omit<UkRegulations, "rejectionReasons">>({
  key: "indUkRegultions",
  default: {
    status: "draft",
    currentStep: 1,
    type: null,
    pastInvestments: null,
    futureInvestments: null,
    futureInvestmentsPercentage: null,
    pastInvestmentsPercentage: null,
    hnwSource: null,
    hnwAmount: null,
    riskConfirmation: false,
    ukPotentialHnw: false,
    assessmentQuestionnaire: [],
    quizPassed: false,
  },
});
