import { useRecoilState } from "recoil";

import { IndUkRegultions } from "atomsStorage/ukRegulations";
import { UkRegulations } from "types/ukRegulations";

export const useIndUkRegultions = () => {
  const [data, setData] = useRecoilState(IndUkRegultions);

  const setUkRegulationsData = (newData: UkRegulations) => setData(newData);

  return { setUkRegulationsData, data };
};
