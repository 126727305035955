import { useRecoilState } from "recoil";

import { defaultStepsProgress, stepsProgressAtom } from "atomsStorage/stepsProgress";

export const useStepsProgress = () => {
  const [stepsProgress, setStepsProgress] = useRecoilState(stepsProgressAtom);

  const resetStepsProgress = () => {
    setStepsProgress(defaultStepsProgress);
  };

  const setCurrentStep = (step: number) => {
    setStepsProgress((prev) => ({ ...prev, currentStep: step }));
  };

  const setStepsPercent = (percent: number) => {
    setStepsProgress((prev) => ({ ...prev, percent }));
  };

  const setScreen = (screen: number) => {
    setStepsProgress((prev) => ({ ...prev, currentScreen: screen }));
  };

  const goToNextScreen = () => {
    setStepsProgress((prev) => ({ ...prev, currentScreen: prev.currentScreen + 1 }));
  };

  const goToPreviousScreen = (callback?: () => void) => {
    if (callback != undefined) callback();

    setStepsProgress((prev) => ({ ...prev, currentScreen: prev.currentScreen - 1 }));
  };

  return {
    stepsProgress,
    resetStepsProgress,
    setCurrentStep,
    setStepsPercent,
    goToNextScreen,
    goToPreviousScreen,
    setScreen,
  };
};
