import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isNil, mergeDeepRight } from "ramda";

import { updateKYC } from "api/kyc";
import { useUser } from "hooks";
import { KycInd, KycOrg } from "types/kyc";
import { ProcessData } from "types/process";

import { useKycData } from "./useKycData";
import { useQueryError } from "./useQueryError";

export const useMutateKyc = (skipRefreshData?: boolean) => {
  const { data: userData } = useUser();
  const { data: kycData } = useKycData();
  const queryClient = useQueryClient();
  const { handleError } = useQueryError();

  return useMutation({
    mutationFn:
      isNil(userData) || isNil(kycData)
        ? undefined
        : (newData: Partial<KycInd | KycOrg>) => {
            return updateKYC(userData.type, newData as KycInd | KycOrg);
          },
    onSuccess: (data) => {
      if (!skipRefreshData) {
        queryClient.setQueryData(["kycData", userData], (prev: any) => {
          return mergeDeepRight(prev as ProcessData<KycInd | KycOrg>, { last: data });
        });
      }
    },
    onError: (error: any) => handleError(error),
  });
};
