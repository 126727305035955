import { atom } from "recoil";

export type PoaStepsProgress = {
  uploadDocsCurrentScreen: number;
};

export const defaultPoaStepsProgress: PoaStepsProgress = {
  uploadDocsCurrentScreen: 1,
} as const;

export const poaStepsProgressAtom = atom<PoaStepsProgress>({
  key: "poaStepsProgressAtom",
  default: defaultPoaStepsProgress,
});
