import React, { useState } from "react";

import { useFormik } from "formik";
import { any, filter, pathOr, pipe } from "ramda";

import { DocumentCopySVG, DocumentTextSVG, LangEnSVG } from "assets/icons";
import { poaDocument } from "assets/images";
import { Alert } from "components/Alert";
import { BackButton } from "components/BackButton";
import DocumentThumbnail from "components/DocumentThumbnail/DocumentThumbnail";
import { FileUpload } from "components/FileUpload";
import { RolledList } from "components/RolledList";
import { SubmitButton } from "components/SubmitButton";
import { DOCUMENT_TYPE } from "constants/formData";
import { acceptableDocuments } from "constants/kyc";
import { useFormikActions, useKycData, useMutateKyc } from "hooks";
import { DocumentFile, FileItem } from "types/formData";

import { validationSchema } from "./validationSchema";

import styles from "../UploadDocuments.module.scss";

type Props = {
  handleGoPrev: () => void;
  handleGoNext: () => void;
  documentType: typeof DOCUMENT_TYPE.proofOfAddressEn;
};

const translationShouldDemonstrate = [
  "confirmation that it’s a ‘true and accurate translation of the date, address and company type contained in the original document’",
  "the date of the translation",
  "the full name and contact details of the translator or a representative of the translation company",
];

export const UploadTranslation = ({ handleGoPrev, handleGoNext, documentType }: Props) => {
  const { data } = useKycData();
  const updateKyc = useMutateKyc();

  const initialValues = {
    translationDocuments: pipe(
      pathOr<DocumentFile[]>([], ["last", "documents"]),
      filter<DocumentFile>((doc) => doc.type === documentType)
    )(data) as (DocumentFile | FileItem)[],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      const res = await handleDocuments(["translationDocuments"]);
      if (!res) return;
      updateKyc.mutateAsync({ currentStep: 6 }).then(() => {
        handleGoNext();
      });
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { handleDocuments, getError, handleFileAdd, handleFileRemove } = useFormikActions(formik);

  const anyFileHasError = any((item: any) => item.error, formik.values.translationDocuments);

  return (
    <div className={styles.uploadDocuments}>
      <h2 data-cy='upload-poa-translation-title'>Upload Proof of Address (English certified translation)</h2>
      <p>Proof of Address document must be different from your photo ID and must be in Roman Lettering.</p>
      <Alert
        description='All documents need to be dated and less than 90 days old at the time of submission'
        type='warning'
        greyOut={true}
      />
      <RolledList
        header={
          <>
            <DocumentCopySVG className={styles.listIcon} />
            <span className={styles.listHeader}>Acceptable documents</span>
          </>
        }
        visibleAmount={4}
        options={acceptableDocuments}
        className={styles.list}
      />
      <RolledList
        header={
          <>
            <DocumentTextSVG className={styles.listIcon} />
            <span className={styles.listHeader}>The document should demonstrate</span>
          </>
        }
        visibleAmount={4}
        options={["Residential address", "Issuer’s letter head", "Date"]}
        className={styles.list}
      />
      <Alert
        description='All information above require a certified English translation'
        type='warning'
        greyOut
        style={{ marginTop: 15 }}
      />
      <RolledList
        header={
          <>
            <LangEnSVG className={styles.listIcon} />
            <span className={styles.listHeader}>Translation should demonstrate</span>
          </>
        }
        options={translationShouldDemonstrate}
        visibleAmount={4}
        className={styles.list}
      />
      <div className={styles.sampleWrapper}>
        <p>Sample</p>
        <DocumentThumbnail samples={[poaDocument]} samplesPreview={[poaDocument]} />
      </div>
      <FileUpload
        handleAdd={handleFileAdd("translationDocuments")}
        handleRemove={handleFileRemove("translationDocuments")}
        documentType={documentType}
        documentProcess='verification'
        files={formik.values.translationDocuments}
        error={getError("translationDocuments")}
        multiple
        disabled={formik.isSubmitting}
      />
      <SubmitButton
        onClick={formik.submitForm}
        disabled={!formik.isValid || anyFileHasError}
        loading={formik.isSubmitting}
      >
        Continue
      </SubmitButton>
      <BackButton onClick={handleGoPrev} />
    </div>
  );
};
