import React from "react";

import { AlertProps } from "antd";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { StatusBadge } from "components/StatusBadge";
import { EDD_PATH } from "constants/pages";
import { PROCESS_STATUS } from "constants/process";
import { ProcessStatus } from "types/process";

import { Alert } from "./Alert";

import styles from "./Alert.module.scss";

type Props = {
  status: ProcessStatus;
};

type AlertRecord = Record<string, string> & {
  type: AlertProps["type"];
  message: string;
  description: string;
  btnText: string;
};
type DictionaryProp = Record<string, AlertRecord>;

const alertParams: DictionaryProp = {
  [PROCESS_STATUS.draft]: {
    type: "warning",
    message: "Enhanced Due Diligence",
    description: "Please provide information on your Source of Funds and Source of Wealth",
    btnText: "Start EDD",
  },
  [PROCESS_STATUS.pending]: {
    type: "warning",
    message: "Enhanced Due Diligence is pending",
    description:
      "If you haven't done so already, proceed with your Account Type Certification and explore our range of products on Bnk To The Future",
    btnText: "",
  },
  [PROCESS_STATUS.expired]: {
    type: "error",
    message: "Enhanced Due Diligence",
    description: "Unfortunately your EDD expired. Please see steps below and start your verification again.",
    btnText: "Resubmit",
  },
};

export const EddAlert = ({ status }: Props) => {
  const navigate = useNavigate();

  const displayStatusBadge = status === PROCESS_STATUS.pending || status === PROCESS_STATUS.rejected;

  const statusContent = alertParams[status as string];

  if (statusContent !== null) {
    return (
      <Alert
        className={cn(styles.alert, styles.edd)}
        type={statusContent.type}
        message={statusContent.message}
        description={<p className={styles.eddAlertDescriptionText}>{statusContent.description}</p>}
        action={
          <div className={styles.eddAction}>
            <Button
              className={styles.actionBtn}
              loading={status === PROCESS_STATUS.pending}
              onClick={() => navigate(EDD_PATH)}
            >
              {statusContent.btnText}
            </Button>

            {displayStatusBadge && (
              <div className={styles.statusContainer}>
                <span>Status:</span>
                <StatusBadge status={status} iconPosition='right' isTierBox pendingUpTo='up to 21 wd' />
              </div>
            )}
          </div>
        }
        closable={false}
      />
    );
  } else {
    return (
      <Alert
        type='error'
        message='Something went wrong'
        description='Unexpected status of EDD, pleas contact support'
        closable={false}
      />
    );
  }
};
