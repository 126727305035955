export const INVESTMENT_LEVEL = {
  to10k: "$1,000-$10,000",
  to50k: "$10,001-$50,000",
  to100k: "$50,001-$100,000",
  over100k: "$100,001+",
} as const;

export const FREQUENCY_LEVEL = {
  to5: "1-5",
  to10: "6-10",
  to25: "11-25",
  to50: "26-50",
  over50: "51+",
} as const;

export const SOURCE_OF_WEALTH_IND = {
  employment_income: "employment_income",
  savings: "savings",
  gift: "gift",
  company_profits: "company_profits",
  investment_income: "investment_income",
  sold_investment: "sold_investment",
  property_sale: "property_sale",
  company_sale: "company_sale",
  compensation: "compensation",
  loans: "loans",
  lottery: "lottery",
  other: "other",
} as const;

export const SOURCE_OF_WEALTH_ORG = {
  business_revenue: "business_revenue",
  contribution_of_the_owners: "contribution_of_the_owners",
  contribution_of_the_investors: "contribution_of_the_investors",
  contribution_of_the_settlors: "contribution_of_the_settlors",
  result_of_investment: "result_of_investment",
  other: "other",
} as const;

export const SOURCE_OF_FUNDS_IND = {
  employment_income: "employment_income",
  inheritance: "inheritance",
  regulatory_intermediary_investment: "regulatory_intermediary_investment",
  passive_income: "passive_income",
  retirement_income: "retirement_income",
  savings: "savings",
  other: "other",
} as const;

export const SOURCE_OF_FUNDS_ORG = {
  business_revenue: "business_revenue",
  contribution_of_the_owners: "contribution_of_the_owners",
  contribution_of_the_investors: "contribution_of_the_investors",
  contribution_of_the_settlors: "contribution_of_the_settlors",
  result_of_investment: "result_of_investment",
  other: "other",
} as const;

export const NET_WORTH = {
  below75k: "below75k",
  to100k: "to100k",
  to200k: "to200k",
  to500k: "to500k",
  to1m: "to1m",
  to5m: "to5m",
  over5m: "over5m",
} as const;

export const NET_PROFIT = {
  below1k: "below1k",
  to2c5k: "to2c5k",
  to5k: "to5k",
  to10k: "to10k",
  to20k: "to20k",
  to50k: "to50k",
  to100k: "to100k",
  to250k: "to250k",
  over250k: "over250k",
} as const;

export const ANNUAL_INCOME = {
  below1k: "below1k",
  to2k: "to2k",
  to5k: "to5k",
  to10k: "to10k",
  to20k: "to20k",
  to50k: "to50k",
  to100k: "to100k",
  to250k: "to250k",
  over250k: "over250k",
} as const;
