import React, { ReactNode } from "react";

import cn from "classnames";

import styles from "./Divider.module.scss";

type Props = {
  vertical?: boolean;
  className?: string;
  icon?: ReactNode;
  iconClassName?: string;
};

export const Divider = ({ vertical, className, icon, iconClassName }: Props) => {
  return (
    <span className={styles.dividerBox}>
      <div className={cn(styles.divider, { [styles.vertical]: vertical }, className)} />
      <div className={cn(styles.icon, iconClassName)}>{icon}</div>
    </span>
  );
};
