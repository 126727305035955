import React, { createContext, ReactNode, useContext } from "react";

import { LoadingIndicator } from "components/LoadingIndicator";
import { Country, useCountries } from "hooks";

type CtxProps = {
  countries: Country[] | undefined;
  countriesOptions:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
  countriesWithStates: string[] | undefined;
  highRiskCountries: string[] | undefined;
  isLoading: boolean;
};

export const CountriesProviderCtx = createContext<CtxProps | undefined>(undefined);

type Props = {
  children: ReactNode;
};

const CountriesProvider = ({ children }: Props) => {
  const { isLoading, ...rest } = useCountries();

  if (isLoading) return <LoadingIndicator />;

  return <CountriesProviderCtx.Provider value={{ ...rest, isLoading }}>{children}</CountriesProviderCtx.Provider>;
};

export default CountriesProvider;

export const useCountriesContext = () => {
  const ctxValue = useContext(CountriesProviderCtx);
  if (ctxValue === undefined) throw new Error("CountriesProvider - Expected context value to be set");
  return ctxValue;
};
