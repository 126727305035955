import { postcodeValidator, postcodeValidatorExistsForCountry } from "postcode-validator";
import { replace } from "ramda";
import * as Yup from "yup";

import { BOOLEAN_ANSWER } from "constants/formData";
import { validationMessages } from "constants/validation";
import { KOREA_ZIP_CODE_PATTERN, UK_ZIP_CODE_PATTERN } from "hooks";

// This regex matches string which consist of digits, "+", "/" and "-" only
export const tinRegex = new RegExp(/^([0-9]|-|\+|\/|[A-Za-z]){9,}$/);
// This regex matches string which consist of digits and "-" only
export const usTinRegex = new RegExp(/^[0-9|-]*$/g);

// If is phone number, minimum 5 characters
export const phoneRegex = new RegExp(/^[0-9]{5,}$/);

// If it doesn't contain any chinese, japanese characters etc.
export const logogramRegex = new RegExp(/(\p{Script=Hani}|\p{Script=Hang}|\p{Script=Hiragana}|\p{Script=Katakana})+/gu);

const onlyDigitsFromString = replace(/\D/gm, "");

Yup.addMethod(Yup.string, "isUsTIN", function () {
  return this.test("us-tin-number", validationMessages.wrongTIN, (value) => {
    const onlyDigits = value && onlyDigitsFromString(value);
    return onlyDigits?.length === 9 && !!value?.match(usTinRegex);
  });
});

Yup.addMethod(Yup.string, "isPhoneNumber", function () {
  return this.test("is-phone-number", validationMessages.wrongPhoneNumber, (value) => !!value?.match(phoneRegex));
});

Yup.addMethod(Yup.string, "isNotLogogram", function () {
  return this.test("is-not-logogram", validationMessages.incorrectLetters, function (value) {
    return value ? !value.match(logogramRegex) : true;
  });
});

Yup.addMethod(Yup.string, "isPostCode", function (countryKey: string) {
  return this.test("is-post-code", validationMessages.wrongPostCode, function (value) {
    const countryCode = this.parent[countryKey] === "GB" ? "UK" : this.parent[countryKey];

    if (countryCode === "KP" || countryCode === "KR") {
      return KOREA_ZIP_CODE_PATTERN.test(value || "");
    }

    if (countryCode === "GB") {
      return UK_ZIP_CODE_PATTERN.test(value || "");
    }

    const validationCountryCode =
      !!countryCode && postcodeValidatorExistsForCountry(countryCode) ? countryCode : "INTL";

    return postcodeValidator(value || "", validationCountryCode);
  });
});

export const checkString = Yup.string().trim().required(validationMessages.required);
export const checkUserText = checkString.isNotLogogram();
export const checkBooleanAnswer = checkString.oneOf([BOOLEAN_ANSWER.yes, BOOLEAN_ANSWER.no]);
export const checkArrayMaxLength = (max: number) =>
  Yup.array().max(max, validationMessages.maxFilesAllowed(max)).min(1, validationMessages.required);
