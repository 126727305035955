import { useRecoilState } from "recoil";

import { poaStepsProgressAtom } from "atomsStorage/poaStepsProgress";

export const usePoaStepsProgress = () => {
  const [poaStepsProgress, setPoaStepsProgress] = useRecoilState(poaStepsProgressAtom);

  const setUploadDocsCurrentScreen = (step: number) => {
    setPoaStepsProgress((prev) => ({ ...prev, uploadDocsCurrentScreen: step }));
  };

  return {
    poaStepsProgress,
    setUploadDocsCurrentScreen,
  };
};
