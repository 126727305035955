import React from "react";

import { Progress } from "antd";
import cn from "classnames";

import { TickCircleSVG } from "assets/icons";

import styles from "./Steps.module.scss";

export type StepsProps = {
  items: string[];
  currentStep: number;
  percent: number;
};

export const Steps = ({ items, currentStep, percent }: StepsProps) => {
  const progressBarsNumber = items.length - 1;

  const renderItem = (item: string, index: number) => {
    const itemPercentage = (index / progressBarsNumber) * 100;
    const isActive = items.length === 1 || (currentStep >= index + 1 && percent >= itemPercentage);

    return (
      <React.Fragment key={`step-item-${item}`}>
        <div className={cn(styles.step, { [styles.active]: isActive })}>
          <TickCircleSVG className={styles.stepIcon} />
          <p>{item}</p>
        </div>
        {index < progressBarsNumber ? (
          <Progress
            percent={(percent - itemPercentage) * progressBarsNumber}
            type='line'
            size={2}
            strokeColor='#FFF'
            showInfo={false}
            trailColor='#FFFFFF7F'
            className={styles.progress}
          />
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div className={cn(styles.stepsContainer, { [styles.center]: items.length === 1 })}>
      {items.map((item, index) => renderItem(item, index))}
    </div>
  );
};
