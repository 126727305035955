import React, { MouseEvent, ReactNode, useState } from "react";

import cn from "classnames";

import { ArrowSVG } from "assets/icons";
import { Button } from "components/Button";

import styles from "./RolledList.module.scss";

export type RolledListProps = {
  header?: ReactNode;
  options: ReactNode[];
  visibleAmount: number;
  className?: string;
};

export const RolledList = ({ header, options, visibleAmount, className }: RolledListProps) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowClick = (e: MouseEvent) => {
    e.preventDefault();
    setShowAll((prev) => !prev);
  };

  return (
    <div className={cn(styles.rolledList, className)}>
      <div className={styles.rolledList__header}>{header}</div>
      <ul>
        {options.map((item, idx) => {
          return showAll ? <li key={idx}>{item}</li> : idx < visibleAmount ? <li key={idx}>{item}</li> : null;
        })}
      </ul>
      {options.length > visibleAmount ? (
        <Button type='link' onClick={handleShowClick} className={styles.expandBtn}>
          {showAll ? "Hide" : "See all"}
          <ArrowSVG className={cn({ [styles.opened]: showAll })} />
        </Button>
      ) : null}
    </div>
  );
};
