import { applySpec, prop } from "ramda";

import { IProcessStateReport } from "types/processStateReport";
import { AdapterMapper } from "types/utils";

class ProcessStatusReportClass {
  receivingMapper = applySpec<IProcessStateReport>({
    currentStatus: prop("currentStatus"),
    expiresAt: prop("expiresAt"),
    lastStatus: prop("lastStatus"),
    previousStatus: prop("previousStatus"),
    rejectionReasons: prop("rejectionReasons"),
    startable: prop("startable"),
    retriableAt: prop("retriableAt"),
    activatedAt: prop("activatedAt"),
  });

  toModel<T = unknown>(data: T) {
    return this.receivingMapper(data);
  }
}

export const ProcessStatusReport = new ProcessStatusReportClass();
