export const PROFILE_TYPE = {
  organizational: "organizational",
  individual: "individual",
} as const;

export const PROFILE_TIER = {
  tier_0: "tier_0",
  tier_0_kyc: "tier_0_kyc",
  tier_1_uk_restricted: "tier_1_uk_restricted",
  tier_1: "tier_1",
  tier_2: "tier_2",
  tier_3: "tier_3",
  tier_4: "tier_4",
} as const;
