import React, { ReactNode } from "react";

import cn from "classnames";

import styles from "./ContentBody.module.scss";
type Props = {
  children: ReactNode;
  className?: string;
};

export const ContentBody = ({ children, className }: Props) => {
  return <section className={cn(styles.contentBody, className)}>{children}</section>;
};
