import { FullCertificationAdapter } from "adapters/fullCertification";
import { ProfileType } from "types/account";
import { FullCertification } from "types/fullCertification";
import { ProcessData } from "types/process";
import { ApiService } from "utils/requestService";

export const getFullCertificationData = (type: ProfileType) =>
  ApiService.get<ProcessData<unknown>>(`/${type}/full_certification`).then((data) =>
    FullCertificationAdapter.toModel(data)
  );

export const updateFullCertification = (type: ProfileType, data: Partial<FullCertification>) =>
  ApiService.patch(`/${type}/full_certification`, data);

export const submitFullCertification = (type: ProfileType) => ApiService.patch(`/${type}/full_certification/submit`);
