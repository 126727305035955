import React from "react";

import { Space } from "antd";

import { CircleIncorrectSVG } from "assets/icons";
import { BackButton } from "components/BackButton";
import { HOME_PATH } from "constants/pages";
import { ProcessStatus } from "types/process";

import styles from "./GuardianAlert.module.scss";

type GuardianAlertProps = {
  customMessage?: string;
};

const GuardianAlert: React.FC<GuardianAlertProps> = ({ customMessage }) => {
  const message =
    customMessage ??
    "We are sorry, but you do not meet the conditions required to access this part of the application. Please return to the Welcome page and check the requirements.";

  return (
    <div className={styles.guardianAlert}>
      <h2>Access Denied</h2>

      <p>{message}</p>
      <br />
      <Space align='center' style={{ width: "100%", justifyContent: "center" }}>
        <CircleIncorrectSVG />
      </Space>
      <BackButton href={HOME_PATH} className={styles.backToDashboard}>
        Back to Dashboard
      </BackButton>
    </div>
  );
};

const acceptableStatuses: ProcessStatus[] = ["pending", "accepted", "submitted", "rejected", "expired"];

export const checkEnabledProcess = (
  previousProcessStatuses: ProcessStatus[],
  currentEddStatus: ProcessStatus | undefined
) => {
  return (
    currentEddStatus !== "draft" &&
    previousProcessStatuses.every((previousProcessStatus) => {
      return acceptableStatuses.includes(previousProcessStatus);
    })
  );
};

export default GuardianAlert;
