import React, { useEffect } from "react";

import { AppHeader } from "components/AppHeader";
import { ContentBody } from "components/ContentBody";
import GuardianAlert from "components/GuardianAlert/GuardianAlert";
import { LoadingIndicator } from "components/LoadingIndicator";
import { Steps } from "components/Steps";
import { VERIFF_STATUS } from "constants/process";
import { useStepsProgress, useKycData } from "hooks";
import { KycInd } from "types/kyc";

import { IndividualKyc } from "./Individual";

import styles from "./kyc.module.scss";

const KYCPage = () => {
  const {
    stepsProgress: { currentStep, percent, currentScreen },
    resetStepsProgress,
    setScreen,
  } = useStepsProgress();
  const { data } = useKycData();

  useEffect(() => {
    const step = data?.last.currentStep || 1;
    if (step <= 1 && [VERIFF_STATUS.approved, VERIFF_STATUS.submitted].includes(data?.last.veriffStatus)) {
      setScreen(2);
    } else {
      setScreen(step);
    }

    return () => {
      resetStepsProgress();
    };
  }, [data?.last.currentStep]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [currentScreen]);

  const renderBody = () => {
    if (!data?.last.startable) {
      return <GuardianAlert />;
    }
    return <IndividualKyc kycData={data.last as KycInd} />;
  };

  return (
    <div className={styles.kycPage}>
      <AppHeader className={styles.appHeader}>
        <div className={styles.header}>
          <h2>KYC</h2>
          <Steps
            items={["Proof of Identity", "Proof of Address", "Summary"]}
            currentStep={currentStep}
            percent={percent}
          />
        </div>
      </AppHeader>
      <ContentBody className={styles.body}>
        {data ? renderBody() : <LoadingIndicator size={200} className={styles.loading} />}
      </ContentBody>
    </div>
  );
};

export default KYCPage;
