export const PROCESS_STATUS = {
  draft: "draft",
  pending: "pending",
  accepted: "accepted",
  submitted: "submitted",
  rejected: "rejected",
  expired: "expired",
  outdated: "outdated",
  blocked: "blocked",
} as const;

export const PROCESS_TYPE = {
  kyc: "kyc",
  virtualAssets: "virtualAssets",
  selfCertification: "selfCertification",
  certification: "certification",
  edd: "edd",
  ukRegulations: "ukRegulations",
} as const;

export const VERIFF_STATUS = {
  created: "created",
  started: "started",
  expired: "expired",
  abandoned: "abandoned",
  submitted: "submitted",
  approved: "approved",
  resubmission_requested: "resubmission_requested",
  declined: "declined",
  null: "null",
} as const;
