import React, { ButtonHTMLAttributes, ReactNode } from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import { HourglassGIF } from "assets/icons";
import { ObjectValues } from "types/utils";

import styles from "./Button.module.scss";

export const BUTTON_TYPE = {
  default: "default",
  outline: "outline",
  outlineGrey: "outlineGrey",
  link: "link",
} as const;

type ButtonType = ObjectValues<typeof BUTTON_TYPE>;

export type ButtonProps = {
  children: ReactNode;
  type?: ButtonType;
  lg?: boolean;
  sm?: boolean;
  loading?: boolean;
  href?: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type">;

export const Button = ({
  type = BUTTON_TYPE.default,
  children,
  lg,
  sm,
  loading,
  href,
  className,
  onClick,
  ...rest
}: ButtonProps) => {
  const isLink = type === BUTTON_TYPE.link;
  const btn = (
    <button
      className={cn(
        styles.btn,
        styles[type],
        { [styles.large]: lg, [styles.small]: sm, [styles.loading]: loading },
        className
      )}
      type='button'
      onClick={(e) => {
        if (loading) return;
        onClick?.(e);
      }}
      {...rest}
    >
      {loading ? <img src={HourglassGIF} className={styles.spinner} style={{ width: 50, height: 50 }} /> : children}
    </button>
  );

  if (isLink) {
    return <Link to={href || "#"}>{btn}</Link>;
  }

  return btn;
};
