import { useCallback } from "react";

import { notification } from "antd";
import { AxiosError } from "axios";

import { redirectToCore } from "api/authentication";
import { BFID_TOKEN } from "constants/authentication";

import { useLocalStorage } from "./useLocalStorage";

export const useQueryError = () => {
  const { removeItem } = useLocalStorage();

  const handleError = useCallback(<T extends AxiosError>(error: T) => {
    console.log("QueryError error: ", error);
    const { status } = error.response || {};

    switch (status) {
      case 401: {
        removeItem(BFID_TOKEN);
        redirectToCore();
        break;
      }
      case 422: {
        console.log(error);
        notification.error({
          message: (error.response?.data as any).message ?? error.message ?? "Something went wrong",
        });
        break;
      }
    }
  }, []);

  return { handleError };
};
