import React from "react";

import { Button, ButtonProps } from "antd";
import cn from "classnames";

import { BackToBfSVG } from "assets/icons";

import styles from "./BackToCoreButton.module.scss";

export const BackToCoreButton = ({ className, children, ...rest }: ButtonProps) => {
  const content = children || (
    <>
      Bnk To The Future <BackToBfSVG />
    </>
  );

  return (
    <Button
      data-cy='core-redirection-btn'
      ghost
      className={cn(styles.backToCoreButton, className)}
      href={process.env.REACT_APP_CORE_HOME}
      {...rest}
    >
      {content}
    </Button>
  );
};
