import { applySpec, prop } from "ramda";

import { AdapterMapper } from "types/utils";

class VeriffAdapterClass {
  receivingMapper = applySpec<{ url: string }>({
    url: prop("redirect_url"),
  });

  toModel<T = unknown>(item: T) {
    return this.receivingMapper(item);
  }
}

export const VeriffAdapter = new VeriffAdapterClass();
