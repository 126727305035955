import React from "react";

import { AlertProps } from "antd";

import { PROCESS_STATUS } from "constants/process";

import { Alert } from "./Alert";

type Props = {
  status: typeof PROCESS_STATUS | string;
  titleParam?: string;
  onClose: () => void;
};

type AlertRecord = Record<string, string> & { type: AlertProps["type"]; message: string; description: string };
type DictionaryProp = Record<string, AlertRecord>;

const text: DictionaryProp = {
  ["about-to-expired-past"]: {
    type: "warning",
    message: "Your identity verification expired %param%",
    description: `Please Update your KYC Verification.`,
  },
  ["about-to-expired"]: {
    type: "warning",
    message: "Your identity verification will expire in %param%",
    description: `Please Update your KYC Verification.`,
  },
  ["va-pending"]: {
    type: "warning",
    message: "Submission successful",
    description: `Your virtual assets submission is pending. We will notify you when ready`,
  },
  [PROCESS_STATUS.expired]: {
    type: "error",
    message: "ID Verification expired",
    description: "Please Update your ID Verification to ensure your account does not expire.",
  },
};

export const KycAlert = ({ status, titleParam = "", onClose }: Props) => {
  return (
    <Alert
      type={text[status as string].type}
      message={text[status as string].message.replace(/%param%/, titleParam)}
      description={text[status as string].description}
      closable={false}
      onClose={onClose}
    />
  );
};
