import React, { useRef } from "react";

import LightboxBase, { LightboxExternalProps, Slide } from "yet-another-react-lightbox";
import { Zoom, Counter } from "yet-another-react-lightbox/plugins";

import "yet-another-react-lightbox/styles.css";
import { ZoomInSVG, ZoomOutSVG, ZoomCloseSVG, LightboxLeftSVG, LightboxRightSVG } from "assets/icons";

import styles from "./Lightbox.module.scss";

const Lightbox = (props: LightboxExternalProps) => {
  const zoomRef = useRef<any>(null);
  const slidesLength = props?.slides?.length ?? 0;

  return (
    <LightboxBase
      {...props}
      plugins={[Zoom, Counter]}
      zoom={{ ref: zoomRef }}
      on={{
        click: () => zoomRef.current?.zoomIn(),
      }}
      className={styles.lightbox}
      carousel={{ finite: true }}
      controller={{ closeOnBackdropClick: true }}
      render={{
        iconClose: () => <ZoomCloseSVG />,
        iconZoomIn: () => <ZoomInSVG />,
        iconZoomOut: () => <ZoomOutSVG />,
        iconPrev: () => <LightboxLeftSVG />,
        iconNext: () => <LightboxRightSVG />,
        slideHeader: ({ slide }: { slide: Slide }) => <div className={styles.description}>{slide.description}</div>,
        buttonPrev: slidesLength <= 1 ? () => null : undefined,
        buttonNext: slidesLength <= 1 ? () => null : undefined,
      }}
      styles={{
        root: { "--yarl__color_backdrop": "rgba(27, 27, 27, .5)", "--yarl__carousel_padding_px": "55px" },
      }}
    />
  );
};

export default Lightbox;
