import React from "react";

import { Collapse } from "antd";
import cn from "classnames";
import { keys, propOr } from "ramda";
import { useNavigate } from "react-router-dom";

import { ArrowSVG } from "assets/icons";
import { Button } from "components/Button";
import { StatusBadge } from "components/StatusBadge";
import { EDD_PATH } from "constants/pages";
import { PROCESS_STATUS } from "constants/process";
import { ProcessStatus } from "types/process";

import { Alert } from "./Alert";

import styles from "./Alert.module.scss";

type Props = {
  status: ProcessStatus;
  rejectionReasons?: Record<string, string>;
};

const errorLabelMap: Record<string, string> = {
  other_reason: "Other reason",
  extra_edd_documents: "Documents",
};

const customExpandIcon = ({ isActive }: { isActive?: boolean }) => (
  <ArrowSVG style={{ transform: isActive ? "rotate(-180deg)" : undefined }} />
);

export const EddRejectionAlert = ({ status, rejectionReasons }: Props) => {
  const navigate = useNavigate();
  const rejectionKeys = keys(rejectionReasons ?? {});
  const action = (
    <div className={styles.customAlertAction}>
      <Button
        className={styles.actionBtn}
        loading={status === PROCESS_STATUS.pending}
        onClick={() => navigate(EDD_PATH)}
      >
        Resubmit
      </Button>

      <div className={styles.statusContainer}>
        <span>Status:</span>
        <StatusBadge status={status} iconPosition='right' isTierBox pendingUpTo='up to 21 wd' />
      </div>
    </div>
  );
  const descriptionElement = (
    <>
      <div className={styles.customAlertBody}>
        <p>Check out the following reasons of Enhanced Due Diligence being rejected</p>

        <div className={styles.alertActionDesktop}>{action}</div>
      </div>

      {rejectionReasons !== undefined && rejectionKeys.length !== 0 && (
        <div className={styles.eddRejectionAlertCollapse}>
          <Collapse
            defaultActiveKey={[]}
            className={styles.rejectionCollapsable}
            expandIcon={customExpandIcon}
            expandIconPosition='end'
          >
            {rejectionKeys.map((key) => (
              <Collapse.Panel
                key={key}
                header={propOr(key, key, errorLabelMap)}
                className={styles.rejectionCollapsable_panel}
              >
                {rejectionReasons[key]}
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      )}

      <div className={styles.alertActionMobile}>{action}</div>
    </>
  );

  return (
    <Alert
      className={cn(styles.alert, styles.edd)}
      type={"error"}
      message='Enhanced Due Diligence rejected'
      description={descriptionElement}
      closable={false}
    />
  );
};
