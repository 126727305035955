import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { isNil } from "ramda";

import { getKycData } from "api/kyc";
import { useUser } from "hooks";

import { useQueryData } from "./useQueryData";

export const useKycData = (
  statusRejected?: boolean,
  options: Partial<UseQueryOptions> & { suspense?: boolean } = {
    queryKey: ["kycData"],
  }
) => {
  const { data } = useUser();

  if (isNil(data)) {
    return { data: undefined, isLoading: false, error: null };
  }

  const queryKey: QueryKey = ["kycData", data];
  const queryFn = () => getKycData(data.type);
  const queryOptions: any = { ...options, enabled: statusRejected !== false && !isNil(data) };

  return useQueryData(queryKey, queryFn, {
    ...queryOptions,
    queryKey,
  });
};
