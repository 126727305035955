import React, { ReactNode } from "react";

import { Col, Row } from "antd";
import cn from "classnames";
import { Link } from "react-router-dom";

import { BFIDLogoSVG } from "assets/icons";
import { HOME_PATH } from "constants/pages";

import styles from "./AppHeader.module.scss";

type Props = {
  children: ReactNode;
  rightSide?: ReactNode;
  className?: string;
};

export const AppHeader = ({ children, rightSide, className }: Props) => {
  return (
    <Row className={cn(styles.appHeader, className)}>
      <Col xs={{ span: 12 }} lg={{ span: 3 }}>
        <Link to={HOME_PATH}>
          <BFIDLogoSVG />
        </Link>
      </Col>
      <Col flex='auto' xs={{ order: 1 }} lg={{ order: 0 }} className={styles.mainCol}>
        {children}
      </Col>
      <Col xs={{ span: 12 }} lg={{ span: 3 }} className={styles.rightSide}>
        {rightSide}
      </Col>
    </Row>
  );
};
