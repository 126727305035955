import React, { useState } from "react";

import { removeDocument } from "api/documents";
import { ArrowNextSVG, LangEnSVG, LangNotEnSVG } from "assets/icons";
import { AnswerOption, AnswerOptionProps } from "components/AnswerOption";
import { BackButton } from "components/BackButton";
import { BOOLEAN_ANSWER, DOCUMENT_TYPE } from "constants/formData";
import { useKycData, useMutateKyc, useStepsProgress } from "hooks";
import { usePoaStepsProgress } from "hooks/usePoaStepsProgress";
import { removeDocuments } from "utils/removePoaOutdatedDocuments";

import styles from "./DocumentsLanguage.module.scss";

type QuestionProps = {
  header: string;
  description: string;
  options: ({ key: string } & AnswerOptionProps)[];
};

export const DocumentsLanguage = () => {
  const [showTranslationQuestion, setShowTranslationQuestion] = useState(false);
  const updateKyc = useMutateKyc();
  const { data } = useKycData();
  const { goToNextScreen, goToPreviousScreen } = useStepsProgress();
  const { setUploadDocsCurrentScreen } = usePoaStepsProgress();

  const handleRemoveDocuments = (callback: () => void) => {
    const documentTypesToRemove = [DOCUMENT_TYPE.proofOfAddressEn];

    removeDocuments({
      documents: data?.last.documents,
      documentTypes: documentTypesToRemove,
      callback,
    });
  };

  const languageQuestion: QuestionProps = {
    header: "Proof Of Address",
    description: "Please select an option",
    options: [
      {
        option: "My document is in English",
        onClick: () => {
          handleRemoveDocuments(() => {
            updateKyc
              .mutateAsync({ hasDocumentInEnglish: true, hasDocumentTranslation: BOOLEAN_ANSWER.no, currentStep: 6 })
              .then(() => {
                setUploadDocsCurrentScreen(1);
                goToNextScreen();
              });
          });
        },
        leftSide: <LangEnSVG />,
        rightSide: <ArrowNextSVG />,
        key: "document-lang-en",
      },
      {
        option: "My document is NOT in English",
        onClick: () => {
          updateKyc.mutate({ hasDocumentInEnglish: false, currentStep: 5 });
          setShowTranslationQuestion(true);
        },
        leftSide: <LangNotEnSVG />,
        rightSide: <ArrowNextSVG />,
        key: "document-lang-not-en",
      },
    ],
  };

  const translationQuestion: QuestionProps = {
    header: "Certified translation",
    description: "Do you have a certified English translation of your Proof of Address?",
    options: [
      {
        option: "Yes",
        onClick: () => {
          updateKyc.mutateAsync({ hasDocumentTranslation: BOOLEAN_ANSWER.yes, currentStep: 6 }).then(() => {
            setUploadDocsCurrentScreen(1);
            goToNextScreen();
          });
        },
        rightSide: <ArrowNextSVG />,
        key: "has-translation",
      },
      {
        option: "No",
        onClick: () => {
          handleRemoveDocuments(() => {
            updateKyc.mutateAsync({ hasDocumentTranslation: BOOLEAN_ANSWER.no, currentStep: 6 }).then(() => {
              setUploadDocsCurrentScreen(1);
              goToNextScreen();
            });
          });
        },
        rightSide: <ArrowNextSVG />,
        key: "has-no-translation",
      },
    ],
  };

  const renderQuestion = () => {
    const dataSource = showTranslationQuestion ? translationQuestion : languageQuestion;
    const { header, description, options } = dataSource;
    return (
      <>
        <h2 data-cy={showTranslationQuestion ? "poa-document-no-eng-title" : "poa-document-lang-title"}>{header}</h2>
        <p>{description}</p>
        <div className={styles.answers}>
          {options.map(({ key, ...props }) => (
            <AnswerOption {...props} dataKey={key} key={key} />
          ))}
        </div>
      </>
    );
  };

  const handleGoBack = () => {
    if (showTranslationQuestion) {
      setShowTranslationQuestion(false);
    } else {
      goToPreviousScreen(() => {
        updateKyc.mutateAsync({ currentStep: 4 });
      });
    }
  };

  return (
    <div className={styles.documentsLanguage}>
      {renderQuestion()}
      <BackButton onClick={handleGoBack} />
    </div>
  );
};
