import { useState } from "react";

export const useTierInfoModal = () => {
  const [open, setOpen] = useState(false);
  const [isOpenFromReadmeBtn, setIsOpenFromReadmeBtn] = useState(false);

  const openModal = (readme?: boolean) => {
    setOpen(true);

    if (readme) {
      setIsOpenFromReadmeBtn(true);
    }
  };

  const closeModal = () => {
    setOpen(false);

    if (isOpenFromReadmeBtn) {
      setIsOpenFromReadmeBtn(false);
    }
  };

  return {
    open,
    isOpenFromReadmeBtn,
    openModal,
    closeModal,
  };
};
