import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { notification } from "antd";
import cn from "classnames";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { appsignal } from "appsignal";
import { DangerGreenSVG, HourglassGIF } from "assets/icons";
import { BUTTON_TYPE, Button } from "components/Button";
import { HOME_PATH } from "constants/pages";
import { useMutateUserDecision } from "hooks/useMutateUkRegulations";
import { ITimeLeft, formatTimeLeft, timeIsUp } from "utils/timeFormater";

import { Alert } from "./Alert";

import styles from "./Alert.module.scss";

type PendingAlertProps = {
  timeLeft?: ITimeLeft;
  blogArticleUrl: string;
};

export const PendingAlert: React.FC<PendingAlertProps> = ({ timeLeft, blogArticleUrl }) => {
  const updateUserDecision = useMutateUserDecision();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleContinueClick = () => {
    setIsLoading(true);
    updateUserDecision
      .mutateAsync({
        decision: "continue",
      })
      .then(() => {
        notification.success({ message: "UK Financial Promotion Session Accepted" });
        queryClient.invalidateQueries({ queryKey: ["userData"] });
        navigate(HOME_PATH);
      })
      .catch((err) => {
        appsignal.sendError(err);
        notification.error({ message: `Something went wrong: ${err?.message ?? "Error unknown"}` });
      });
  };

  const handleDeclineClick = () => {
    setIsLoading(true);
    updateUserDecision
      .mutateAsync({
        decision: "decline",
      })
      .then(() => {
        notification.success({ message: "UK Financial Promotion Session Rejected" });
        queryClient.invalidateQueries({ queryKey: ["userData"] });
        navigate(HOME_PATH);
      })
      .catch((err) => {
        appsignal.sendError(err);
        notification.error({ message: `Something went wrong: ${err?.message ?? "Error unknown"}` });
      });
  };

  const actionsContent = () => {
    if (isLoading)
      return (
        <Button
          type={BUTTON_TYPE.outline}
          disabled
          className={classNames(styles.actionBtn, { [styles.timerBtn]: !!timeLeft })}
          loading={true}
        >
          Loading...
        </Button>
      );

    if (timeIsUp(timeLeft))
      return (
        <>
          <Button
            type={BUTTON_TYPE.default}
            className={classNames(styles.actionBtn, styles.inlineBtn)}
            onClick={handleContinueClick}
          >
            Continue
          </Button>
          <Button
            type={BUTTON_TYPE.default}
            className={classNames(styles.actionBtn, styles.inlineBtn)}
            onClick={handleDeclineClick}
          >
            Decline
          </Button>
        </>
      );

    return (
      <Button
        type={BUTTON_TYPE.outline}
        disabled
        className={classNames(styles.actionBtn, { [styles.timerBtn]: !!timeLeft })}
      >
        {formatTimeLeft(timeLeft)}
      </Button>
    );
  };

  return (
    <Alert
      data-cy='uk-fin-prom-alert'
      className={cn(styles.alert, styles.ukRestrictedPromotion)}
      type='success'
      icon={<DangerGreenSVG />}
      message='Assesment successfully verified'
      description={
        <div className={styles.ukRestrictedAlertBody}>
          <p>
            You have successfully passed the Assessment for UK Financial Promotion. There is a 24-hour reflection period
            for you to consider if this high-risk investment is suitable for you. Please take a moment to{" "}
            <a href={blogArticleUrl} target='_blank' rel='noreferrer'>
              learn more
            </a>
            , and please check your account back in 24h
          </p>

          <div className={classNames(styles.ukRegulationsAction, styles.inlineAlertAction)}>{actionsContent()}</div>
        </div>
      }
      closable={false}
    />
  );
};
