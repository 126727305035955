import { UseQueryOptions, QueryKey, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { isNil } from "ramda";

import { getFullCertificationData } from "api/fullCertification";
import { FullCertification } from "types/fullCertification";
import { ProcessData } from "types/process";

import { useQueryData } from "./useQueryData";
import { useUser } from "./useUser";

type QueryType = UseQueryResult<ProcessData<FullCertification>, Error & AxiosError<unknown, any>>;

export const useFullCertificationData = (
  statusRejected?: boolean,
  options?: Partial<UseQueryOptions<unknown, Error & AxiosError<unknown, any>, unknown, QueryKey>>
): QueryType => {
  const { data } = useUser();

  if (isNil(data)) {
    return { data: undefined, isLoading: false, error: null } as QueryType;
  }

  const queryKey: QueryKey = ["fullCertification", data];
  const queryFn = () => getFullCertificationData(data.type);

  return useQueryData(queryKey, queryFn, {
    ...options,
    queryKey,
    enabled: statusRejected !== false,
  }) as QueryType;
};
