import React from "react";

import cn from "classnames";

import { ClockFilledSVG, ClockSVG, CloseCircleSVG, DangerSVG, OpenClockSVG, TickCircleSVG } from "assets/icons";
import { Tooltip } from "components/Tooltip";
import { PROCESS_STATUS } from "constants/process";
import { ProcessStatus } from "types/process";

import styles from "./StatusBadge.module.scss";

type Props = {
  status: ProcessStatus | "comingSoon";
  customText?: string;
  pendingUpTo?: string;
  isAboutToExpired?: boolean;
  isTierBox?: boolean;
  isGlobalStatus?: boolean;
  isKYCStatus?: boolean;
  iconPosition?: "left" | "right";
  displayTooltips?: boolean;
  draftShouldUseDefaultTextColor?: boolean;
  "data-cy"?: string;
};

export const StatusBadge = ({
  status,
  pendingUpTo,
  customText,
  isAboutToExpired,
  isTierBox,
  isGlobalStatus,
  isKYCStatus,
  iconPosition = "left",
  displayTooltips = false,
  draftShouldUseDefaultTextColor,
  ...props
}: Props) => {
  const isIconPositionLeft = iconPosition === "left";

  if (isAboutToExpired) {
    return (
      <span {...props} className={cn(styles.statusBadge, styles.aboutToExpired)}>
        {isIconPositionLeft && (
          <Tooltip shouldDisplay={displayTooltips} title='About to expire'>
            <TickCircleSVG />
          </Tooltip>
        )}{" "}
        {customText ?? "Issues found"} {!isIconPositionLeft && <TickCircleSVG />}
      </span>
    );
  }

  switch (status) {
    case PROCESS_STATUS.draft:
      return (
        <span {...props} className={cn(styles.statusBadge, { [styles[status]]: !draftShouldUseDefaultTextColor })}>
          {isIconPositionLeft && (
            <Tooltip shouldDisplay={displayTooltips} title='Unverified'>
              <TickCircleSVG />
            </Tooltip>
          )}{" "}
          {customText ??
            (isTierBox ? "Unverified" : isGlobalStatus || isKYCStatus ? "No issues found" : "No status yet")}{" "}
          {!isIconPositionLeft && <TickCircleSVG />}
        </span>
      );
    case PROCESS_STATUS.pending:
    case PROCESS_STATUS.submitted:
      return (
        <div>
          <span {...props} className={cn(styles.statusBadge, styles[status])}>
            {isIconPositionLeft && (
              <Tooltip shouldDisplay={displayTooltips} title='Pending'>
                <ClockSVG />
              </Tooltip>
            )}
            {customText ?? <span>Pending</span>}
            {!isIconPositionLeft && <ClockSVG />}
            {pendingUpTo && isTierBox && <div className={styles.pendingUpToText}>{pendingUpTo}</div>}
          </span>

          {pendingUpTo && !isTierBox && <div className={styles.pendingUpToText}>{pendingUpTo}</div>}
        </div>
      );
    case PROCESS_STATUS.accepted:
      return (
        <span {...props} className={cn(styles.statusBadge, styles[status])}>
          {isIconPositionLeft && (
            <Tooltip shouldDisplay={displayTooltips} title='Verified'>
              <TickCircleSVG />
            </Tooltip>
          )}{" "}
          {customText ?? (isGlobalStatus || isKYCStatus ? "No issues found" : "Verified")}{" "}
          {!isIconPositionLeft && <TickCircleSVG />}
        </span>
      );
    case PROCESS_STATUS.rejected:
      return (
        <span {...props} className={cn(styles.statusBadge, styles[status])}>
          {isIconPositionLeft && (
            <Tooltip shouldDisplay={displayTooltips} title='Rejected'>
              <CloseCircleSVG />
            </Tooltip>
          )}{" "}
          {customText ?? (isGlobalStatus ? "Issues found" : <span>Rejected</span>)}{" "}
          {!isIconPositionLeft && <CloseCircleSVG />}
        </span>
      );
    case PROCESS_STATUS.outdated:
      return (
        <span {...props} className={cn(styles.statusBadge, styles[status])}>
          {isIconPositionLeft && (
            <Tooltip shouldDisplay={displayTooltips} title='Outdated'>
              <DangerSVG />
            </Tooltip>
          )}{" "}
          {customText ?? "Outdated"} {!isIconPositionLeft && <DangerSVG />}
        </span>
      );
    case PROCESS_STATUS.expired:
      return (
        <span {...props} className={cn(styles.statusBadge, styles[status])}>
          {isIconPositionLeft && (
            <Tooltip shouldDisplay={displayTooltips} title='Expired'>
              <ClockFilledSVG />
            </Tooltip>
          )}{" "}
          {customText ?? (isGlobalStatus ? "Issues found" : "Expired")} {!isIconPositionLeft && <ClockFilledSVG />}
        </span>
      );
    case "comingSoon":
      return (
        <span {...props} className={cn(styles.statusBadge, styles[status])}>
          {isIconPositionLeft && (
            <Tooltip shouldDisplay={displayTooltips} title='Coming soon'>
              <OpenClockSVG />
            </Tooltip>
          )}{" "}
          {customText ?? "Coming soon"} {!isIconPositionLeft && <OpenClockSVG />}
        </span>
      );
    default:
      return null;
  }
};
