import * as Yup from "yup";

export const validationSchema = Yup.object({
  sanctionedCountryQuestions: Yup.lazy((questions: object) => {
    const keysValidation = Object.keys(questions).reduce(
      (validation, key) => ({
        ...validation,
        [key]: Yup.array().of(
          Yup.object().shape({
            question: Yup.string().required(),
            answer: Yup.string().oneOf(["yes", "no"]).required(),
          })
        ),
      }),
      {}
    );

    return Yup.object().shape({
      ...keysValidation,
    });
  }),
});
