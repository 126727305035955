import React, { useState } from "react";

import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import { Col, Row } from "antd";

import { getVeriffUrl } from "api/kyc";
import {
  CameraSVG,
  CloseCircleSVG,
  DocumentBlurSVG,
  DocumentCutSVG,
  DocumentFlashSVG,
  DocumentGoodSVG,
  DrivingLicenseSVG,
  LocationTickSVG,
  PassportSVG,
  TickCircleSVG,
} from "assets/icons";
import { Alert } from "components/Alert";
import { LoadingIndicator } from "components/LoadingIndicator";
import { SubmitButton } from "components/SubmitButton";
import { useMutateKyc, useStepsProgress } from "hooks";

import styles from "./ProofOfIdentityInit.module.scss";

const essentials = [
  {
    title: "Passport",
    description: "Recommended",
    icon: <PassportSVG />,
  },
  {
    title: "Driving License",
    description: "Possible longer waiting time",
    icon: <DrivingLicenseSVG />,
  },
  {
    title: "Residency Permit",
    description: "Possible longer waiting time",
    icon: <LocationTickSVG />,
  },
  {
    title: "Camera",
    description: "Camera access on your device",
    icon: <CameraSVG />,
  },
];

const alignments = [
  {
    title: "All good",
    icon: <TickCircleSVG className={styles.good} />,
    documentIcon: <DocumentGoodSVG />,
  },
  {
    title: "No cut",
    icon: <CloseCircleSVG />,
    documentIcon: <DocumentCutSVG />,
  },
  {
    title: "No blurry",
    icon: <CloseCircleSVG />,
    documentIcon: <DocumentBlurSVG />,
  },
  {
    title: "No flash",
    icon: <CloseCircleSVG />,
    documentIcon: <DocumentFlashSVG />,
  },
];

const Essential = ({ title, icon, description }: (typeof essentials)[number]) => (
  <div className={styles.essential}>
    <div>{icon}</div>
    <div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);

const DocumentAlignment = ({ title, documentIcon, icon }: (typeof alignments)[number]) => (
  <Col className={styles.documentCell} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 12 }}>
    <div className={styles.documentExample}>{documentIcon}</div>
    <div className={styles.documentDescription}>
      {icon}
      {title}
    </div>
  </Col>
);

export const ProofOfIdentityInit = () => {
  const [isVerifying, setIsVerifying] = useState(false);
  const { goToNextScreen, setStepsPercent, setCurrentStep } = useStepsProgress();
  const updateKyc = useMutateKyc();

  const handleVeriffClick = () => {
    getVeriffUrl().then(({ url }) => {
      setStepsPercent(15);
      setIsVerifying(true);
      createVeriffFrame({
        url,
        onEvent: (msg) => {
          switch (msg) {
            case MESSAGES.FINISHED: {
              updateKyc.mutateAsync({ currentStep: 3 }).then(() => {
                setCurrentStep(3);
                goToNextScreen();
              });
              break;
            }
            case MESSAGES.CANCELED: {
              setStepsPercent(5);
              setIsVerifying(false);
              break;
            }
          }
        },
      });
    });
  };

  return (
    <div className={styles.proofOfIdentityInit}>
      <h2>Proof of Identity</h2>
      {isVerifying ? (
        <LoadingIndicator className={styles.loading} />
      ) : (
        <>
          <p>
            Identity verification is a process where we confirm your identity in order to meet the essential regulatory
            requirements ensuring your account is investment-ready.
          </p>
          <p>Ensure you have valid ID document and access to camera</p>
          {essentials.map((props) => (
            <Essential {...props} key={props.title} />
          ))}
          <p>Keep in mind when scanning your document</p>
          <Row className={styles.documentAlignments}>
            {alignments.map((props) => (
              <DocumentAlignment {...props} key={props.title} />
            ))}
          </Row>
          <Alert
            message='Be Appropriately Dressed'
            description='Please be advised that your camera is about to capture an image of you. Please be APPROPRIATELY DRESSED and be aware of your surroundings. Submitting inappropriate images may result in being blocked from the service and/or result in the suspension of your account.'
            type='warning'
          />
          <SubmitButton onClick={handleVeriffClick}>Continue with Veriff</SubmitButton>
        </>
      )}
    </div>
  );
};
