import { applySpec, prop } from "ramda";

import { UkRegulations } from "types/ukRegulations";
import { AdapterMapper } from "types/utils";

class UkRegulationsAdapterClass {
  receivingMapper = applySpec<UkRegulations>({
    status: prop("status"),
    currentStep: prop("currentStep"),
    type: prop("type"),
    pastInvestments: prop("pastInvestments"),
    futureInvestments: prop("futureInvestments"),
    futureInvestmentsPercentage: prop("futureInvestmentsPercentage"),
    pastInvestmentsPercentage: prop("pastInvestmentsPercentage"),
    hnwSource: prop("hnwSource"),
    hnwAmount: prop("hnwAmount"),
    riskConfirmation: prop("riskConfirmation"),
    ukPotentialHnw: prop("ukPotentialHnw"),
    activatedAt: prop("activatedAt"),
    retriableAt: prop("retriableAt"),
    assessmentQuestionnaire: prop("assessmentQuestionnaire"),
    quizPassed: prop("quizPassed"),
  });

  takeOutData = (item: unknown) => {
    return this.receivingMapper(item);
  };

  toModel<T>(item: T) {
    return this.takeOutData(item);
  }
}

export const UkRegulationsAdapter = new UkRegulationsAdapterClass();
