import React, { ComponentType, useEffect, useState } from "react";

import { LoadingIndicator } from "components/LoadingIndicator";
import { useUser, useUserData } from "hooks";

export const withAuthenticate = (WrappedComponent: ComponentType) => () => {
  const { isLoading } = useUserData();
  const { data } = useUser();

  if (!data.name && isLoading) {
    return <LoadingIndicator size={200} style={{ height: "100vh" }} />;
  }

  return <WrappedComponent />;
};
