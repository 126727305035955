import { DocumentFile, FileItem } from "types/formData";
import { ApiService } from "utils/requestService";

export const uploadDocument = (item: FileItem) => {
  const data = new FormData();
  data.append("file", item.file);
  data.append("type", item.type);
  data.append("process", item.process);
  return ApiService.post<DocumentFile>("/documents", data, { headers: { "Content-Type": "multipart/form-data" } });
};

export const removeDocument = (id: string) => ApiService.delete(`/documents/${id}`);
