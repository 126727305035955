const sanctionedCountryQuestions: { [key: string]: string[] } = {
  LB: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Lebanon or to any destination that will ultimately result in its use in Lebanon?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in Lebanon?",
  ],
  RU: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Russia or to any destination that will ultimately result in its use in Russia?",
    "Do you or your organisation import arms or related materials/technology from Russia or originating in Russia?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in Russia?",
    "Do you or your organisation export, sell, supply or transfer dual-use goods/technology, that are or may be intended for military use, to Russia or to any destination that will ultimately result in its use in Russia?",
    "Do you or your organisation provide technical or financial assistance or services to any individual or organisation in Russia related to dual-use goods/technology intended for military use?",
    "Do you or your organisation export, sell, supply or transfer goods/technology used in oil exploration/production in Russia or to any destination that will ultimately result in its use in Russia?",
    "Do you or your organisation provide oil production/exploration related technical or financial assistance or services to any individual or organisation in Russia?",
    "Do you have or are you in the process of creating a joint venture with entities in Crimea or Sevastopol?",
    "Do you or your organisation grant financing to entities in Crimea or Sevastopol?",
    "Do you or your organisation have or are in the process of acquiring real-estate or real-estate-related entities in Crimea or Sevastopol?",
    "Do you or your organisation have, or are in the process of, acquiring or increasing participation in entities in Crimea or Sevastopol?",
    "Do you or your organisation import goods originating in Crimea/ Sevastopol or provide directly or indirectly financing or financial assistance relating to such activities?",
    "Do you or your organisation export, sell, supply or transfer goods/technology, to Crimea/ Sevastopol or to any destination that will ultimately result in its use in Crimea/ Sevastopol?",
    "Do you or your organisation provide technical assistance, brokering, construction or engineering services directly related to the infrastructure in Crimea/Sevastopol relating to transport, telecommunication, energy or the prospection/exploration of oil, gas or mineral resources?",
    "Do you or your organisation provide services directly related to tourism activities in Crimea/Sevastopol?",
  ],
  UA: [
    "Do you have or are you in the process of creating a joint venture with entities in Crimea or Sevastopol?",
    "Do you or your organisation grant financing to entities in Crimea or Sevastopol?",
    "Do you or your organisation have or are in the process of acquiring real-estate or real-estate-related entities in Crimea or Sevastopol?",
    "Do you or your organisation have, or are in the process of, acquiring or increasing participation in entities in Crimea or Sevastopol?",
    "Do you or your organisation import goods originating in Crimea/ Sevastopol or provide directly or indirectly financing or financial assistance relating to such activities?",
    "Do you or your organisation export, sell, supply or transfer goods/technology, to Crimea/ Sevastopol or to any destination that will ultimately result in its use in Crimea/ Sevastopol?",
    "Do you or your organisation provide technical assistance, brokering, construction or engineering services directly related to the infrastructure in Crimea/Sevastopol relating to transport, telecommunication, energy or the prospection/exploration of oil, gas or mineral resources?",
    "Do you or your organisation provide services directly related to tourism activities in Crimea/Sevastopol?",
  ],
  CN: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to China or to any destination that will ultimately result in its use in China?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in China?",
  ],
  AF: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to listed individuals?",
    "Do you or your organisation provide arms-related technical advice, training or assistance to any listed individuals?",
  ],
  BY: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Belarus or to any destination that will ultimately result in its use in Belarus?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in Belarus?",
    "Do you or your organisation export, sell, supply or transfer equipment that may be used for the internal repression or related materials/technology to Belarus or to any destination that will ultimately result in its use in Belarus?",
    "Do you or your organisation provide technical or financial assistance or services related to equipment that may be used for internal repression, to any individual or organisation in Belarus?",
  ],
  MM: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Burma or to any destination that will ultimately result in its use in Burma?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in Burma?",
    "Do you or your organisation export dual-use goods and technology to Burma that may be intended for military use, military end-user or Border Guard Police use?",
    "Do you or your organisation provide related technical or financial assistance or services to any individual or organisation in Burma related to dual-use goods?",
    "Do you or your organisation export, sell, supply or transfer equipment that may be used for the internal repression or related materials/technology to Burma or to any destination that will ultimately result in its use in Burma?",
    "Do you or your organisation provide technical or financial assistance or services related to equipment that may be used for internal repression, to any individual or organisation in Burma?",
    "Do you or your organisation export, sell, supply or transfer telecommunications monitoring and interception equipment or related materials/technology to Burma or to any destination that will ultimately result in its use in Burma?",
    "Do you or your organisation provide technical or financial assistance or services related to telecommunications monitoring and interception equipment, to any individual or organisation in Burma?",
    "Do you or your organisation provide military training to or military cooperation with the Myanmar Armed Forces and Border Guard Police.",
  ],
  CF: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to the Central African Republic or to any destination that will ultimately result in its use in the Central African Republic?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in the Central African Republic?",
  ],
  CD: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to any non-governmental entities or individuals operating in the DRC or to that will ultimately result in its use in the DRC?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any non-governmental individual or organisation in the DRC?",
  ],
  ER: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Eritrea or to any destination that will ultimately result in its use in Eritrea?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in Eritrea?",
  ],
  IR: [
    "Do you or your organisation export, sell, supply or transfer equipment that may be used for the internal repression or related materials/technology to Iran or to any destination that will ultimately result in its use in Iran?",
    "Do you or your organisation provide technical or financial assistance or services related to equipment that may be used for internal repression, to any individual or organisation in Iran?",
    "Do you or your organisation export, sell, supply or transfer telecommunications/Internet monitoring and interception equipment or related materials/technology/ Software to Iran or to any destination that will ultimately result in its use in Iran?",
    "Do you or your organisation provide technical or financial assistance or services related to telecommunications/Internet monitoring and interception equipment/software, to any individual or organisation in Iran?",
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Iran or to any destination that will ultimately result in its use in Iran?",
    "Do you or your organisation import arms or related materials/technology from Iran or originating in Iran?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to any individual or organisation in Iran?",
    "Do you or your organisation export, sell, supply or transfer dual-use goods/technology, that are or may be intended for military use, to Iran or to any destination that will ultimately result in its use in Iran?",
  ],
  IQ: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Iraq or to entities or individuals operating in the Iraq or that will ultimately result in its use in the Iraq?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to Iraq or to any individuals or entities in Iraq?",
    "Do you import, export or deal in Iraqi Cultural property or other items listed in Annex II of Council Regulation (EC) No 1210/2003?",
  ],
  SO: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Somali or to entities or individuals operating in Somalia or that will ultimately result in its use in Somalia?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to Somalia or to any individuals or entities in Somalia?",
    "Do you or your organisation import charcoal from Somalia or provide related financing or financial assistance related to the import of charcoal from Somalia?",
  ],
  SS: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to South Sudan or to entities or individuals operating in South Sudan or that will ultimately result in its use in South Sudan?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to South Sudan or to any individuals or entities in South Sudan?",
  ],
  SD: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Sudan or to entities or individuals operating in Sudan or that will ultimately result in its use in Sudan?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to Sudan or to any individuals or entities in Sudan?",
  ],
  YE: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Yemen or to entities or individuals operating in Yemen or that will ultimately result in its use in Yemen?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to Yemen or to any individuals or entities in Yemen?",
  ],
  ZW: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Zimbabwe or to entities or individuals operating in Zimbabwe or that will ultimately result in its use in Zimbabwe?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to Zimbabwe or to any individuals or entities in Zimbabwe?",
    "Do you or your organisation export, sell, supply or transfer equipment that may be used for the internal repression or related materials/technology to Zimbabwe or to any destination that will ultimately result in its use in Zimbabwe?",
    "Do you or your organisation provide technical or financial assistance or services related to equipment that may be used for internal repression, to any individual or organisation in Zimbabwe?",
  ],
  VE: [
    "Do you or your organisation export, sell, supply or transfer arms or related materials/technology to Venezuela or to entities or individuals operating in Venezuela or that will ultimately result in its use in Venezuela?",
    "Do you or your organisation provide arms-related technical or financial assistance or services to Venezuela or to any individuals or entities in Venezuela?",
    "Do you or your organisation export, sell, supply or transfer equipment that may be used for the internal repression or related materials/technology to Venezuela or to any destination that will ultimately result in its use in Venezuela?",
    "Do you or your organisation provide technical or financial assistance or services related to equipment that may be used for internal repression, to any individual or organisation in Venezuela?",
    "Do you or your organisation export, sell, supply or transfer telecommunications/Internet monitoring and interception equipment or related materials/technology/ Software to Venezuela or to any destination that will ultimately result in its use in Venezuela?",
    "Do you or your organisation provide technical or financial assistance or services related to telecommunications/Internet monitoring and interception equipment/software, to any individual or organisation in Venezuela?",
  ],
};

export type SanctionedQuestionForCountry = {
  [key in keyof typeof sanctionedCountryQuestions]: {
    question: string;
    answer: string | null;
  }[];
};

export default sanctionedCountryQuestions;
