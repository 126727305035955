import { SelfCertificationAdapter } from "adapters/selfCertification";
import { ProfileType } from "types/account";
import { ProcessData } from "types/process";
import { SelfCertificationInd, SelfCertificationOrg } from "types/selfCertification";
import { ApiService } from "utils/requestService";

export const getSelfCertificationData = (type: ProfileType) =>
  ApiService.get<ProcessData<unknown>>(`/${type}/self_certification`).then((data) =>
    SelfCertificationAdapter.toModel(data, type)
  );

export const getRiskAversionData = (type: ProfileType) =>
  ApiService.get<{ total_score: number; aversion_level: "extra_care" }>(`/${type}/certification/risk_aversion`).then(
    (data) => data
  );

export const updateSelfCertification = (
  type: ProfileType,
  data: Partial<SelfCertificationInd | SelfCertificationOrg>
) => ApiService.patch(`/${type}/self_certification`, data);

export const submitSelfCertification = (type: ProfileType) => ApiService.patch(`/${type}/self_certification/submit`);
