import { useMemo } from "react";

import { useRecoilState } from "recoil";

import { AccountAdapter } from "adapters/account";
import { userAtom } from "atomsStorage/user";
import { PROCESS_STATUS } from "constants/process";
import { IAccount } from "types/account";

export const useUser = () => {
  const [data, setData] = useRecoilState(userAtom);

  const setUserData = (newData: IAccount) => setData(AccountAdapter.toModel(newData));

  const isEDDProcessing = useMemo(
    () => data.edd?.lastStatus === PROCESS_STATUS.draft || data.edd?.lastStatus === PROCESS_STATUS.pending,
    [data]
  );

  const isEDDBlocking = useMemo(
    () =>
      data.edd?.currentStatus === PROCESS_STATUS.draft ||
      data.edd?.currentStatus === PROCESS_STATUS.rejected ||
      data.edd?.currentStatus === PROCESS_STATUS.expired,
    [data]
  );

  const isPromotionRequestProcessing = useMemo(
    () =>
      data.promotionRequest?.currentStatus !== null && data.promotionRequest?.currentStatus !== PROCESS_STATUS.accepted,
    [data]
  );

  const isBlockedByPromotionRequest = useMemo(
    () =>
      data.promotionRequest?.currentStatus !== null &&
      data.promotionRequest?.currentStatus !== PROCESS_STATUS.accepted &&
      data.promotionRequest?.currentStatus !== PROCESS_STATUS.pending,
    [data]
  );

  const isEDDRequired = useMemo(() => {
    return data.edd?.lastStatus === PROCESS_STATUS.draft;
  }, [data.edd]);

  const isVerificationBlocked = useMemo(() => {
    return data.verificationBlocked;
  }, [data.verificationBlocked]);

  return {
    setUserData,
    data,
    isEDDRequired,
    isEDDProcessing,
    isVerificationBlocked,
    isPromotionRequestProcessing,
    isBlockedByPromotionRequest,
    isEDDBlocking,
  };
};
