import React from "react";

import cn from "classnames";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { BUTTON_TYPE, Button } from "components/Button";
import { UK_REGULATIONS } from "constants/pages";
import { ITimeLeft, formatTimeLeft } from "utils/timeFormater";

import { Alert } from "./Alert";

import styles from "./Alert.module.scss";

type SecondTryAlertProps = {
  timeLeft?: ITimeLeft;
  startable?: boolean;
  blogArticleUrl: string;
};

export const SecondTryAlert: React.FC<SecondTryAlertProps> = ({ timeLeft, startable, blogArticleUrl }) => {
  const navigate = useNavigate();
  const isTimeLeftExist = timeLeft?.hours ?? timeLeft?.minutes ?? timeLeft?.seconds;

  const handleOnButtonClick = () => {
    if (startable) {
      navigate(UK_REGULATIONS);
    }
  };

  return (
    <Alert
      data-cy='uk-fin-prom-alert'
      className={cn(styles.alert, styles.ukRestrictedPromotion)}
      type='warning'
      message='New UK Regulations'
      description={
        <div className={styles.ukRestrictedAlertBody}>
          <p>
            Based on your answers, we could not approve your assessment as it is deemed to be inappropriate to you. We
            are applying a cooloff period of 24h before you can take further action. Check our{" "}
            <a href={blogArticleUrl} target='_blank' rel='noreferrer'>
              blog article
            </a>{" "}
            to learn more in the meantime.
          </p>

          <div className={classNames(styles.customAlertAction, styles.ukRegulationsAction)}>
            {(!!isTimeLeftExist || startable) && (
              <Button
                data-cy='uk-fin-prom-cta'
                type={startable ? BUTTON_TYPE.default : BUTTON_TYPE.outline}
                disabled={!!isTimeLeftExist || !startable}
                className={classNames(styles.actionBtn, { [styles.timerBtn]: !!isTimeLeftExist })}
                onClick={handleOnButtonClick}
              >
                {isTimeLeftExist && !startable ? formatTimeLeft(timeLeft) : "Start Assessment"}
              </Button>
            )}
          </div>
        </div>
      }
      closable={false}
    />
  );
};
