import React, { CSSProperties } from "react";

import { Spin } from "antd";
import cn from "classnames";

import { LoadingSVG } from "assets/icons";

import styles from "./LoadingIndicator.module.scss";

type Props = {
  size?: number;
  className?: string;
  style?: CSSProperties;
};

export const LoadingIndicator = ({ size = 40, className, style }: Props) => {
  return (
    <div className={cn(styles.indicatorContainer, className)} style={style}>
      <Spin
        indicator={
          <LoadingSVG className={cn("ant-spin-dot-spin", styles.loader)} style={{ width: size, height: size }} />
        }
        spinning
      />
    </div>
  );
};
