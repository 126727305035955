import { applySpec, prop } from "ramda";

import { FullCertification } from "types/fullCertification";
import { ProcessData } from "types/process";

class FullCertificationAdapterClass {
  receivingMapper = applySpec<FullCertification>({
    status: prop("status"),
    currentStep: prop("currentStep"),
    rejectionReasons: prop("rejectionReasons"),
    documents: prop("documents"),
    type: prop("type"),
    regulatedInstitutionDetails: prop("regulatedInstitutionDetails"),
    proofOfAddressType: prop("proofOfAddressType"),
    hasDocumentTranslation: prop("hasDocumentTranslation"),
  });

  takeOutData = (item: unknown) => {
    return this.receivingMapper(item);
  };

  toModel<T extends ProcessData<unknown>>(item: T) {
    return {
      last: this.takeOutData(item.last),
      current: this.takeOutData(item.current),
    };
  }
}

export const FullCertificationAdapter = new FullCertificationAdapterClass();
