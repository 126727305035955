import React from "react";

import { Space } from "antd";
import { useFormik } from "formik";
import { clone, pickAll, isNil } from "ramda";

import { BackButton } from "components/BackButton";
import { Question } from "components/Question";
import { SubmitButton } from "components/SubmitButton";
import { useKycData, useMutateKyc, useStepsProgress } from "hooks";
import { KycInd } from "types/kyc";
import { getSanctionedQuestionsForCountry } from "utils/formData";

import { appsignal } from "../../../../appsignal";

import { validationSchema } from "./validationSchema";

import styles from "./SanctionedCountryQuestions.module.scss";

export const SanctionedCountryQuestions = () => {
  const { data } = useKycData();
  const { goToNextScreen, goToPreviousScreen } = useStepsProgress();

  if (isNil(data)) return null;

  const baseData = pickAll(["sanctionedCountryQuestions", "nationality", "addressCountry"], clone(data.last as KycInd));

  const initialValues = {
    sanctionedCountryQuestions: getSanctionedQuestionsForCountry({
      nationality: baseData.nationality,
      addressCountry: baseData.addressCountry,
      answeredSanctionedQuestionsForCountry: baseData.sanctionedCountryQuestions,
    }),
  };

  const updateKyc = useMutateKyc();

  const formik = useFormik({
    initialValues: { ...initialValues },
    onSubmit: (data, { setSubmitting }) => {
      updateKyc
        .mutateAsync({ ...data, currentStep: 7 })
        .then(() => {
          setSubmitting(false);
          goToNextScreen();
        })
        .catch((err) => {
          setSubmitting(false);
          appsignal.sendError(err);
        });
    },
    validationSchema,
    validateOnMount: true,
  });

  const handleAnswerChange = (key: string, index: number, newAnswer: string) => {
    formik.setFieldValue(
      "sanctionedCountryQuestions",
      Object.keys(formik.values.sanctionedCountryQuestions).reduce(
        (updatedQuestions, countryKey) => ({
          ...updatedQuestions,
          [countryKey]: formik.values.sanctionedCountryQuestions[countryKey].map((sanctionedQuestion, questionIndex) =>
            questionIndex === index && countryKey === key
              ? {
                  question: sanctionedQuestion.question,
                  answer: newAnswer,
                }
              : sanctionedQuestion
          ),
        }),
        {}
      )
    );
  };

  return (
    <div className={styles.documentsLanguage}>
      <h2>Sanctioned Country Questions</h2>
      <Space direction='vertical' size={20} style={{ marginTop: 20 }}>
        {Object.entries(formik.values.sanctionedCountryQuestions).map(([countryKey, questions]) =>
          questions.map((question, index) => (
            <div
              className={styles.questionBox}
              key={`sanctioned-question-${countryKey}-${question.question}-${question.answer}`}
            >
              <h4>{question.question}</h4>
              <Question
                key={`sanctioned-question-${index}`}
                options={[
                  { option: "Yes", value: "yes" },
                  { option: "No", value: "no" },
                ]}
                value={question.answer || ""}
                onClick={(answer) => handleAnswerChange(countryKey, index, answer)}
                answerType={"radio"}
              />
            </div>
          ))
        )}
        <SubmitButton onClick={formik.submitForm} disabled={!formik.isValid} loading={formik.isSubmitting}>
          Continue
        </SubmitButton>
      </Space>
      <BackButton
        onClick={() => {
          goToPreviousScreen(() => {
            updateKyc.mutateAsync({ currentStep: 3 });
          });
        }}
      />
    </div>
  );
};
