import { DateTime } from "luxon";

export const isAboutToExpired = (expiryDate: string) => {
  const EXPIRATION_PERIOD = Number(process.env.REACT_APP_EXPIRATION_DEADLINE_WEEKS ?? "3");

  const deadline = DateTime.local({ zone: "Europe/London" }).plus({ weeks: EXPIRATION_PERIOD });
  const expiryDateTime = DateTime.fromFormat(expiryDate, "yyyy-MM-dd");

  return expiryDateTime <= deadline;
};

export const diffFromNow = (date?: string) => {
  if (!date) {
    return "Invalid Date";
  }

  const dateDateTime = DateTime.fromFormat(date, "yyyy-MM-dd", { zone: "Europe/London" });
  return dateDateTime.toRelative({ locale: "en" })?.replace("in ", "");
};
