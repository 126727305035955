import * as React from "react";

import { POSTCODE_REGEXES } from "postcode-validator/lib/cjs/postcode-regexes";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as RandExp from "randexp";

import { useCountriesContext } from "contexts/countriesCtx";

const DEFAULT_ZIP_CODE_PATTERN = /^\d{5}(?:[-\s]\d{4})?$/;

export const KOREA_ZIP_CODE_PATTERN = /^\d{5}(\d{1})?$/;
export const UK_ZIP_CODE_PATTERN =
  /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|[A-HK-Y][0-9]{1,2}|[A-HK-Y][0-9][A-HJKS-UW]|[0-9][A-HJKSTUW]?)[0-9][ABD-HJLNP-UW-Z]{2})$/;

export const usePostcodeSample = (countryCode: string) => {
  const { countries } = useCountriesContext();
  const postcodeSample = React.useMemo(() => {
    let zipCodePattern = DEFAULT_ZIP_CODE_PATTERN;

    if (countryCode === "KP" || countryCode === "KR") {
      zipCodePattern = KOREA_ZIP_CODE_PATTERN;
    } else if (countryCode === "GB") {
      zipCodePattern = UK_ZIP_CODE_PATTERN;
    } else if (countryCode && POSTCODE_REGEXES.has(countryCode)) {
      zipCodePattern = POSTCODE_REGEXES.get(countryCode) as RegExp;
    }

    const randexp = new RandExp.default(zipCodePattern);

    return randexp.gen().replace(/\s/g, "");
  }, [countryCode]);

  const country = countries?.find((country) => country.value === countryCode)?.label;

  return country ? `Example zip code: "${postcodeSample}".` : "Select country from dropdown";
};
